import { Component, OnInit } from '@angular/core';

import { LocationService } from '../../../../services/location.service';
import { Location_Inspection } from '../../../../models/location_inspection';
import { TabletService } from '../../../../services/tablet.service';
import { Tablet } from '../../../../models/tablet';
import { JobService } from '../../../../services/job.service';

import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { CompanyService } from '../../../../services/company.service';
import { SnotifyService } from 'ng-snotify';
import { AuthService } from '../../../../services/auth.service';
import { User } from '../../../../models/user';
import { ColourService } from '../../../../services/colour.service';
import { Colour } from '../../../../models/colour';

@Component({
  selector: 'app-job-manage',
  templateUrl: './job-manage.component.html',
  styleUrls: ['./job-manage.component.css']
})
export class JobManageComponent implements OnInit {
  companies;
  locations;
  locationList;
  fromAmend: boolean;
  isEdit: boolean = false;
  isDay: boolean = true;
  users: Tablet[];
  colours: Colour[];
  pdA_User_ID: number = -1;
  job: Location_Inspection;
  user: string;

  constructor(public companyService: CompanyService,
  public locationService: LocationService,
  public tabletService: TabletService,
  public jobService: JobService,
  public authService: AuthService,
  public colourService: ColourService,
  public router: Router,
  private snotifyService: SnotifyService,
  public route: ActivatedRoute) {
    this.job = new Location_Inspection();
    this.job.scheduled_Date = moment(moment().format("YYYY-MM-DD") + ' ' + "08:00").toDate();
    this.job.scheduled_End_Date = moment(moment().format("YYYY-MM-DD") + ' ' + "16:30").toDate();
    this.job.startTime = moment(moment().format("YYYY-MM-DD") + ' ' + "08:00").toDate();
    this.job.endTime = moment(moment().format("YYYY-MM-DD") + ' ' + "16:30").toDate();
    this.job.fullDay = true;
    this.job.halfDay = false;
    this.job.otherDay = false;
    this.job.colour_ID = 1;

    this.isDay = true;
    this.tabletService.GetAllCompetent().then((data) => {
      this.users = data;
      //console.log("users");
      //console.log(data);
    });

    this.companyService.getAll().then((data) => {
      this.companies = data;
    });

    this.locationService.getAll().then((data) => {
      this.locationList = data;
      this.locations = this.locationList;
    });
    this.colourService.getAll().then((data) => {
      this.colours = data;
      //console.log(this.colours);

    });
    


    let firstname = this.authService.currentUser.value.firstname.toString();
    let surname = this.authService.currentUser.value.lastname.toString();
    this.user = firstname + " " + surname;
    //console.log(this.authService.currentUser.value);
    //console.log(this.authService.currentUser.value.userId);
    

  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id != undefined) {
        this.jobService.get(id).then((data) => {
          this.job = data;
          this.job.is_edit = true;
          this.user = this.job.booked_By;
          if (this.job.otherDay) {
            this.isDay = false;
          }
          this.job.scheduled_Date = moment(this.job.scheduled_Date).toDate();
          this.job.scheduled_End_Date = moment(this.job.scheduled_End_Date).toDate();

          console.log(this.job);
          console.log(this.locationList);

        });

      }
    });

  }

  
  
  onCompanyChange(value) {
    this.job.locationId = -1;
    if (value == 0) { value = null; }
    this.locations = this.locationList.filter(c => {
      return value == null || c.companyId == value;
    });
  }

  addUser() {

    if (this.pdA_User_ID == undefined) {
      this.pdA_User_ID = undefined;
      return;
    }
    var user = null;
    //let user = this.users.find((u) => {
    //  return u.pdA_User_ID == this.pdA_User_ID;
    //});
    for (var a = 0; a < this.users.length; a++) {
      if (this.users[a].pdA_User_ID == this.pdA_User_ID) {
        user = this.users[a];
        break;
      }
    }


    console.log(user);
    let idx = this.job.engineerIds.indexOf(this.job.engineerIds.find(a => a.pdA_User_ID == user.pdA_User_ID));
    console.log(idx);
    if (idx > -1) {
      return;
    }
    if (this.job.engineerIds.length == 0)
      user.isLead = true;
      
    user.requiresSTR = true;
    this.job.engineerIds.push(user);
    //this.job.engineerIds.push(user.pdA_User_ID);
    console.log(this.job.engineerIds);
  }

  removeUser(user) {
    let idx = this.job.engineerIds.indexOf(user);
    this.job.engineerIds.splice(idx, 1);
    //this.job.engineerIds.splice(idx, 1);
    if (user.isLead) {
      if (this.job.engineerIds.length > 0) {
        this.makeUserLead(this.job.engineerIds[0]);
      }
    }
  }
  requiresSTR(user) {
    if (!user.isLead) user.requiresSTR = !user.requiresSTR;
    
  }
  makeUserLead(user) {
    if (!user.isLead) {
      for (var a = 0; a < this.job.engineerIds.length; a++) {
        var u = this.job.engineerIds[a];
        u.isLead = false;
      }
    }
    user.isLead = !user.isLead;
    user.requiresSTR = true;
  }

  dateChange() {
    console.log("DATE CHANGE");
    if (this.isDay == true) {
      this.job.scheduled_End_Date = this.job.scheduled_Date;
    }
    
  }

  halfDay() {
    this.job.startTime = moment(moment().format("YYYY-MM-DD") + ' ' + "08:00").toDate();
    this.job.endTime = moment(moment().format("YYYY-MM-DD") + ' ' + "12:00").toDate();
    this.job.scheduled_End_Date = this.job.scheduled_Date;
    this.job.fullDay = false;
    this.job.halfDay = true;
    this.job.otherDay = false;
    this.isDay = true;
  }
  fullDay() {
    this.job.startTime = moment(moment().format("YYYY-MM-DD") + ' ' + "08:00").toDate();
    this.job.endTime = moment(moment().format("YYYY-MM-DD") + ' ' + "16:30").toDate();
    this.job.scheduled_End_Date = this.job.scheduled_Date;
    this.job.fullDay = true;
    this.job.halfDay = false;
    this.job.otherDay = false;
    this.isDay = true;
  }
  otherDay() {
    this.job.fullDay = false;
    this.job.halfDay = false;
    this.job.otherDay = true;
    this.isDay = false;
  }

  async submit() {
    if (!this.valid()) {
      return;
    }

    if (this.job.jobType != 4) {
      this.job.includeEquip = true;
    } 
    var response = await this.jobService.create(this.job,
      this.authService.currentUser.value.userId);
    if (response.success) {
      if ((this.job.is_edit && (this.job.jobType == 5 || (this.job.jobType == 4 && !this.job.includeEquip)))
        || (this.job.is_edit && this.authService.currentUser.value.userTypeId != 3)) {
        this.snotifyService.success("Successfully updated job");
        this.redirectToJobs();
      } else {
        
        if (this.job.jobType == 5 || (this.job.jobType == 4 && !this.job.includeEquip)) {
          this.snotifyService.success("Successfully created job");
          this.redirectToJobs();
        } else if (!this.job.is_edit || this.authService.currentUser.value.userTypeId == 3) {
          this.redirectToAddEquipment(response.message)
        }
      }
 
    } else {
      this.snotifyService.error("Error creating job");

    }
    
  }
  valid() {
    var valid = true;
    if (this.job.engineerIds.length == 0) {
      valid = false;
      this.snotifyService.error('Please add at least 1 engineer');
    }
    if (this.job.engineerIds.filter(engineer => engineer.isLead === true).length != 1) {
      valid = false;
      this.snotifyService.error('Please have 1 lead engineer');
    }

    if (this.job.jobNo == '' || this.job.jobNo == undefined) {
      this.snotifyService.error('Please add a job number');
      valid = false;
    }

    if (this.job.scheduled_End_Date < this.job.scheduled_Date) {
      this.snotifyService.error('Please add a end date after the start date');
      valid = false;
    }
    if (this.job.company_Id == undefined || Number(this.job.company_Id) <= 0) {
      this.snotifyService.error('Please select company');
      valid = false;
    }
    if (this.job.locationId == undefined || Number(this.job.locationId) <= 0) {
      this.snotifyService.error('Please select location');
      valid = false;
    }
    return valid;
  }

  redirectToAddEquipment(Id) {
    this.router.navigate(['administration/jobs/manage-equipment'], { queryParams: { id: Id, isEdit: this.job.is_edit } });
  }
  redirectToJobs() {
    this.router.navigate(['administration/jobs']);
  }

  onJobTypeChanged(value) {
    // Get our latest job for this type
    this.jobService.getJobNo(value).then((data) => {
      this.job.jobNo = data;
    });
  }

  back() {
    this.router.navigate(['administration/jobs']); 
     
  }

}

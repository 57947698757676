import { Component, OnInit } from '@angular/core';
import { Location } from '../../../../models/location';
import { Company } from '../../../../models/company';
import { Category } from '../../../../models/category';
import { Page } from '../../../../models/paging/page';
import { Equipment } from '../../../../models/equipment';
import { CompanyService } from '../../../../services/company.service';
import { LocationService } from '../../../../services/location.service';
import { CategoryService } from '../../../../services/category.service';
import { EquipmentService } from '../../../../services/equipment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs as importedSaveAs } from 'file-saver';
import { ColumnService } from '../../../../services/column.service';
import { AuthService } from '../../../../services/auth.service';
import { AppComponent } from '../../../../app.component';
import { StorageService } from '../../../../services/storage.service';
import { JobService } from '../../../../services/job.service';
import { Location_Inspection } from '../../../../models/location_inspection';
import { forEach } from '@angular/router/src/utils/collection';
import { ConfirmationService } from 'primeng/api';
import { SnotifyService } from 'ng-snotify';
import { ModalService } from '../../../../services/modal.service';

@Component({
  selector: 'app-job-manage-equipment',
  templateUrl: './job-manage-equipment.component.html',
  styleUrls: ['./job-manage-equipment.component.css']
})
export class JobManageEquipmentComponent implements OnInit {

  filter: any = {
    company_Id: 0,
    location_Id: 0,
    category_Id: 0,
    serial_Num: '',
    asset_Num: '',
    internal_Location: '',
    quickSearch: '',
    decommissioned: false,
    archived: false
  };
  asset_List = [];
  user;
  init: boolean;
  categories: Category[];
  toggleOpen = false;
  job: Location_Inspection;
  isService = false;

  page = new Page();
  rows = Array<Equipment>();

  isEdit: boolean = false;
  notRepair = true;
  repairRequireInspection = false;
  selectedRepairEquipment: Equipment;

  public readonly pageLimitOptions = [
    { value: 5 },
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ];


  public currentPageLimit: number = 10;

  columns = [
    { name: 'Description', prop: 'description' },
    { name: 'Serial #', prop: 'serial_Num' },
    { name: 'Asset #', prop: 'asset_Num' },
    { name: 'SWL', prop: 'swl' },
    { name: 'Category', prop: 'category' },
    { name: 'Manufacturer', prop: 'manufacturer' },
    { name: 'Date of Manufacturer', prop: 'date_Of_Manuf_Formatted' },
    { name: 'Decommissioned', prop: 'decommissioned_Formatted' },
    { name: 'Created', prop: 'created_Formatted' },
    { name: 'Owner', prop: 'owner_Formatted' },
    { name: 'Original CE', prop: 'original_Cert' },
    { name: 'Location', prop: 'location' },
    { name: 'Company', prop: 'company' },
    { name: 'Other Details', prop: 'other_Details' },
    { name: 'Internal Location', prop: 'internal_Location' },
  ];

  allColumns = [
    { name: 'Description', prop: 'description' },
    { name: 'Serial #', prop: 'serial_Num' },
    { name: 'Asset #', prop: 'asset_Num' },
    { name: 'SWL', prop: 'swl' },
    { name: 'Category', prop: 'category' },
    { name: 'Manufacturer', prop: 'manufacturer' },
    { name: 'Date of Manufacturer', prop: 'date_Of_Manuf_Formatted' },
    { name: 'Decommissioned', prop: 'decommissioned_Formatted' },
    { name: 'Created', prop: 'created_Formatted' },
    { name: 'Owner', prop: 'owner_Formatted' },
    { name: 'Original CE', prop: 'original_Cert' },
    { name: 'Location', prop: 'location' },
    { name: 'Company', prop: 'company' },
    { name: 'Other Details', prop: 'other_Details' },
    { name: 'Internal Location', prop: 'internal_Location' },
  ];




  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this.columns = this.columns.filter(c => {
        return c.name !== col.name;
      });
    } else {
      this.columns = [...this.columns, col];
    }
    this.toggleSelection();
  }

  isChecked(col) {
    return (
      this.columns.find(c => {
        return c.name === col.name;
      }) !== undefined
    );
  }

  toggleSelection() {
    this.toggleOpen = !this.toggleOpen;
  }

  constructor(public companyService: CompanyService, public locationService: LocationService, public categoryService: CategoryService,
    public equipmentService: EquipmentService, public router: Router, private authService: AuthService, public modalService: ModalService,
    private columnService: ColumnService, private storageService: StorageService, public jobService: JobService,
    private confirmationService: ConfirmationService, public route: ActivatedRoute, private snotifyService: SnotifyService) {
    this.selectedRepairEquipment = new Equipment();
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.user = localStorage.getItem("currentUser");
    this.user = JSON.parse(this.user);
  }


  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      const value = params['isEdit'];
      this.isEdit = value ? value.toLocaleLowerCase() === 'true' : false;
      console.log(this.isEdit);
      if (id != undefined) {
        this.jobService.get(id).then((data) => {
          this.job = data;
          this.job.is_edit = true;
          this.filter.location_Id = this.job.locationId;
          this.currentPageLimit = this.page.size;
          
          if (this.job.jobType == 3) {
            this.isService = true;
            this.categoryService.getAllService().then((data) => {
              this.categories = data;
            });
          } else {
            this.isService = false;
            this.categoryService.getAll().then((data) => {
              this.categories = data;
            });
          }
          if (this.job.jobType == 6) {
            this.notRepair = false;
          }
          this.loadTable(this.page, false);
          this.loadAssetList();
          
          
        });
      }
    });
    
  }
  resetFilters() {
    this.filter = {
      company_Id: 0,
      location_Id: this.job.locationId,
      category_Id: 0,
      serial_Num: '',
      asset_Num: '',
      internal_Location: '',
      quickSearch: '',
      decommissioned: false,
      archived: false
    };
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.currentPageLimit = this.page.size;
  }




  // ###################################
  // TABLE
  // ###################################
  public onLimitChange(limit: any): void {
    this.page.size = parseInt(limit, 10);
    this.loadTable(this.page, false);
  }
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page, false);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page, false);
  }

  loadTable(page: Page, clicked) {
     if (clicked) {
      this.page.pageNumber = 0;
      this.currentPageLimit = this.page.size;
      this.setPage({ offset: 0 });
      return;
    }
    if (this.filter.company_Id == null || this.filter.company_Id == undefined) {
      this.filter.company_Id = 0;
    }
    if (this.filter.location_Id == null || this.filter.location_Id == undefined) {
      this.filter.location_Id = 0;
    }
    this.equipmentService.getJobEquipResults(this.page, this.filter.location_Id,
      this.filter.category_Id, this.filter.serial_Num, this.filter.asset_Num, this.filter.internal_Location, this.filter.quickSearch, this.job.location_Inspection_ID, false, this.isService).then((pagedData) => {
      this.page = pagedData.page;
        this.rows = pagedData.data;
      if (this.init) {
        this.gotoTop();
        this.init = false;
      }
      this.refreshbasketrow();        
      });
    
    this.getcolumns();
  }

  Save() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to save these assets to this job?</br><br/>' + 'Total Assets on this Job: ' + this.asset_List.length,
      accept: () => {
       
        if (this.job.jobType != 6) {
          this.jobService.saveEquipment(this.asset_List, this.job.location_Inspection_ID).then((data) => {
            //this.page.pageNumber = 0;
            //this.page.size = 10;
            //this.loadTable(this.page);
            console.log(data);
            if (data.success) {
              console.log(this.isEdit);
              console.log(this.isEdit == false ? "Not Edit" : "Is Edit");
              if (this.isEdit == false) {
                this.snotifyService.success("Successfully created job");
              } else {
                this.snotifyService.success("Successfully updated job");
              }
              this.router.navigate(['administration/jobs']);
            }

          });
        } else {
          console.log("IS REPAIR");
          this.jobService.saveRepairEquipment(this.asset_List, this.job.location_Inspection_ID).then((data) => {
            //this.page.pageNumber = 0;
            //this.page.size = 10;
            //this.loadTable(this.page);
            console.log(data);
            if (data.success) {
              console.log(this.isEdit);
              console.log(this.isEdit == false ? "Not Edit" : "Is Edit");
              if (this.isEdit == false) {
                this.snotifyService.success("Successfully created job");
              } else {
                this.snotifyService.success("Successfully updated job");
              }
              this.router.navigate(['administration/jobs']);
            }

          });
        }
        
      }
    });
    
  }

  getAll(add,remove) {
    this.equipmentService.getJobEquipResults(this.page, this.filter.location_Id,
      this.filter.category_Id, this.filter.serial_Num, this.filter.asset_Num, this.filter.internal_Location, this.filter.quickSearch, this.job.location_Inspection_ID, true, this.isService).then((pagedData) => {
        if (pagedData.data.length > 0) {
          if (add) {
            this.asset_List = [];
            for (let i = 0; i < pagedData.data.length; i++) {
              const jsonObj = pagedData.data[i];
              this.asset_List.push(jsonObj.equipment_ID);
            }
            this.refreshbasketrow();
            
          }
          else if (remove) {            
            this.asset_List = [];
            this.refreshbasketrow();
            
          }
          
          
        }
      });
  }

  loadAssetList() {
    this.equipmentService.getJobEquipResults(this.page, this.filter.location_Id,
      this.filter.category_Id, this.filter.serial_Num, this.filter.asset_Num, this.filter.internal_Location, this.filter.quickSearch, this.job.location_Inspection_ID, true, this.isService).then((pagedData) => {
        if (pagedData.data.length > 0) {
          if (this.job.jobType != 6) {
            for (let i = 0; i < pagedData.data.length; i++) {
              const jsonObj = pagedData.data[i];
              if (jsonObj.addedAsset) {
                this.asset_List.push(jsonObj.equipment_ID);
              }

            }
            this.refreshbasketrow();
            if (this.asset_List.length == 0 && this.job.jobType != 6) {

              this.getAll(true, false);
            }
          } else {
            for (let i = 0; i < pagedData.data.length; i++) {
              const jsonObj = pagedData.data[i];
              if (jsonObj.addedAsset) {
                var equip = {
                  equipment_ID: jsonObj.equipment_ID,
                  requireInspection: jsonObj.repair_Require_Inspection,
                  repairDescription: jsonObj.repair_Description

                };

                this.asset_List.push(equip);
              }

            }
            this.refreshbasketrow();
            if (this.asset_List.length == 0 && this.job.jobType != 6) {

              this.getAll(true, false);
            }
          }
          
          
        }
      });

  }

  addAllAssets() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to add all assets in the current filter?</br><br/>',
      accept: () => {
        this.getAll(true, false);
      }
    });
  }
  removeAllAssets() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to remove all assets from this job?</br><br/>' ,
      accept: () => {
        this.getAll(false, true);
      }
    });

    

  }

  refreshbasketrow() {
    console.log(this.rows);
    if (this.job.jobType != 6) {
      for (const row of this.rows) {
        if (this.asset_List.includes(row.equipment_ID)) {
          row.addedAsset = 1;
        } else {
          row.addedAsset = 0;
        }

      }
    } else {
      for (const row of this.rows) {
        for (const asset of this.asset_List) {
          if (row.equipment_ID == asset.equipment_ID) {
            row.addedAsset = 1;
            break;
          } else {
            row.addedAsset = 0;
          }

        }

      }
    }
    
  }

  getcolumns() {
    this.columnService.getColumns(this.authService.currentUser.value.userId, "job_manage_equipment").then((data) => {
      if (data.columns != null) {
        this.columns = this.columns.filter(c => {
          return data.columns.includes(c.name);
        });
        this.toggleOpen = false;
      }

    });
  }
  SaveSelection() {
    let result = this.columns.map(a => a.name);
    this.columnService.save(this.authService.currentUser.value.userId, "job_manage_equipment", result).then((data) => {
    });
  }

  openModel(id) {
    this.modalService.open(id);
  }

  addRepairPopup(row) {
    this.selectedRepairEquipment = row;
    var category = this.categories.filter(d => {
      return d.category_ID == this.selectedRepairEquipment.category_ID;
    });
    this.repairRequireInspection = category[0].repair_Requires_Inspection;
    if (this.repairRequireInspection) {
      this.selectedRepairEquipment.requireInspection = 1;
    } else {
      this.selectedRepairEquipment.requireInspection = -1;
    }
    this.openModel('popup-add-repair-equipment');

  }
  addrepairequipment(row) {
    if (row.requireInspection == -1) {
      this.snotifyService.error("Please select if this asset will require inspecting after the repair has been carried out");
      return;
    }
    if (row.repairDescription == undefined || row.repairDescription == '') {
      this.snotifyService.error("Please enter what needs to be repaired");
      return;
    }
    var equip = {
      equipment_ID: this.selectedRepairEquipment.equipment_ID,
      requireInspection: this.selectedRepairEquipment.requireInspection = +this.selectedRepairEquipment.requireInspection ,
      repairDescription: this.selectedRepairEquipment.repairDescription
    }
    this.asset_List.push(equip);
    row.addedAsset = 1;
    this.modalService.close('popup-add-repair-equipment');
  }

  addAsset(row) {
    this.asset_List.push(row.equipment_ID);
    row.addedAsset = 1;

  }
  removeAsset(row) {
    let idx = this.asset_List.indexOf(row.equipment_ID);
    this.asset_List.splice(idx, 1);
    row.addedAsset = 0;

  }



  gotoTop() {
    // window.scrollTo(-9999,-99999);
    let top = document.getElementById('top');

    if (top !== null) {
      top.scrollIntoView(true);
      //  document.getElementById("top").scrollTop -= 99999;
      top = null;
    }
  }
}

import { Equipment_Extra } from './equipment_Extra';

export class Equipment {
  equipment_ID = 0;
  location_Id: number;
  description: string;
  description_ID: number;
  serial_Num: string;
  asset_Num: string;
  swl: string;
  manuf_ID = 1;
  original_Cert: number;
  owner_ID: number;
  date_Of_Manuf = '';
  date_Of_First_Use = '';
  category_ID: number;
  other_Details: string;
  internal_Location: string;
  proof_Load: string;
  cE_Num: string;
  loc_Of_Id_Marking: string;
  date_Of_Last_Inspection: string;
  decommisioned = false;
  is_edit = false;
  addedAsset;
  addedQuickPass;

  extras: Equipment_Extra[] = [];

  company_Id = 0;
  inspection_Status = 0;
  inspection_Interval = 12;
  inspection_Interval_Week;

  userId = ''
  interval: number;
  last_Inspection_ID: number;
  completed = false;
  archived = false;
}

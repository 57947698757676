import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { PagedData } from '../models/paging/paged-data';
import { Page } from '../models/paging/page';
import { ServerData } from '../models/paging/server-data';

import { Tablet } from '../models/tablet';
import { UUID } from 'angular2-uuid';
import { WebUser } from '../models/webUser';
import { SnotifyService } from 'ng-snotify';
import { environment } from '../../environments/environment';
import { ApiResponse } from '../models/api-response';

@Injectable()
export class WebUserService {
  constructor(private http: HttpClient, private snotifyService: SnotifyService) { }
  
  public get(id: number): Promise<WebUser> {
    const headers = {
      'Content-Type': 'application/json',
    };
    return new Promise((resolve, reject) => {
      this.http.get<WebUser>(environment.apiUrl + 'webuser/get?id=' + id, { headers: headers }).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public save(user: WebUser): Promise<ApiResponse> {
    return new Promise((resolve, reject) => {
      const headers = {
        'Content-Type': 'application/json',
      };


      this.http.post<ApiResponse>(environment.apiUrl + 'webuser/post', user, { headers: headers }).subscribe(data => {
        resolve(data);

      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getResults(page: Page, company_Id: number, email: string, role: number, quickSearch:string): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'Firstname'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'asc'; }

      let params = '&company_Id=' + company_Id;
      params += '&email=' + email;
      params += '&quickSearch=' + quickSearch;
      //params += '&role=' + role;

      this.http.get<ServerData>(environment.apiUrl + 'webuser/gettable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.total;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }
  public getExcel(company_Id: number, email: string, role: number, quickSearch: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let params = '?company_Id=' + company_Id;
      params += '&email=' + email;
      params += '&quickSearch=' + quickSearch;


      this.http.get<any>(environment.apiUrl + 'webuser/getexcel' + params).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/vnd.ms-excel');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  converBase64toBlob(content, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = window.atob(content); //method which converts base64 to binary
    let byteArrays = [
    ];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }
}

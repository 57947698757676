import { Component, OnInit } from '@angular/core';
import { Company } from '../../../models/company';
import { Location } from '../../../models/location';
import { Tablet } from '../../../models/tablet';

import { saveAs as importedSaveAs } from 'file-saver';

import * as moment from 'moment';
import { Page } from '../../../models/paging/page';
import { AuthService } from '../../../services/auth.service';
import { CompanyService } from '../../../services/company.service';
import { LocationService } from '../../../services/location.service';
import { TabletService } from '../../../services/tablet.service';
import { ServiceInspectionService } from '../../../services/service-inspection.service';
import { ReportableService } from '../../../services/reportable.service';
import { Basket_Item } from '../../../models/Basket_Item';
import { BasketService } from '../../../services/basket.service';
import { InspectionService } from '../../../services/inspection.service';
import { ProjectService } from '../../../services/project.service';
import { ColumnService } from '../../../services/column.service';
import { StorageService } from '../../../services/storage.service';


@Component({
  selector: 'app-project-inspections',
  templateUrl: './project-inspections.component.html',
  styleUrls: ['./project-inspections.component.css']
})
export class ProjectInspectionsComponent implements OnInit {



  companies: Company[];
  locations: Location[];
  locationList: Location[];
  users: Tablet[];

  filter: any = {
    companyId: 0,
    locationId: 0,
    pdaUserId: 0,
    date_Range: 1,
    start_Date: moment().add(-1, 'months').toDate(),
    end_Date: moment().toDate(),
    quickSearch: '',
    job_No: ''
  };
  viewtype: 0;
  page = new Page();
  rows = Array<any>();
  init: boolean;
  is_client = false;
  client;
  toggleOpen = false;
  user;

  public readonly pageLimitOptions = [
    { value: 5 },
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ];


  public currentPageLimit: number = 10;

  columns = [
    { name: 'Work Carried Out', prop: 'works_Carried_Out' },
    { name: 'Job Number', prop: 'job_No' },
    { name: 'Inspected Date', prop: 'inspected_At_Formatted' },
    { name: 'Engineers', prop: 'users' },
    { name: 'Location', prop: 'location' },
    { name: 'Company', prop: 'company' }
  ];

  allColumns = [
    { name: 'Work Carried Out', prop: 'works_Carried_Out' },
    { name: 'Job Number', prop: 'job_No' },
    { name: 'Inspected Date', prop: 'inspected_At_Formatted' },
    { name: 'Engineers', prop: 'users' },
    { name: 'Location', prop: 'location' },
    { name: 'Company', prop: 'company' }
  ];




  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this.columns = this.columns.filter(c => {
        return c.name !== col.name;
      });
    } else {
      this.columns = [...this.columns, col];
    }
    this.toggleSelection();
  }

  isChecked(col) {
    return (
      this.columns.find(c => {
        return c.name === col.name;
      }) !== undefined
    );
  }

  toggleSelection() {
    this.toggleOpen = !this.toggleOpen;
  }

  constructor(private authService: AuthService, companyService: CompanyService, locationService: LocationService, private columnService: ColumnService,
    tabletService: TabletService, public projectInspectionService: ProjectService, public reportableService: ReportableService,
    public basketService: BasketService, private storageService: StorageService) {
    this.is_client = authService.currentUser.value.userType == 0;
    this.client = authService.currentUser.value;

    this.page.pageNumber = 0;
    this.page.size = 10;
    this.page.sortOrder = 'desc';
    this.page.sortBy = "Inspected_At_Formatted";

    this.user = localStorage.getItem("currentUser");
    this.user = JSON.parse(this.user);
    //console.log(this.user);
    this.is_client = this.user.userTypeId == 1;


    companyService.getAll().then((data) => {
      this.companies = data;

      if (this.is_client) {
        this.filter.companyId = this.user.companyId;
        this.filter.locationId = 0;
      }

      locationService.getAll().then((data) => {
        this.locationList = data;
        this.locations = this.locationList;

        if (this.is_client || this.filter.companyId != 0) {
          this.locations = this.locationList.filter(c => {
            return c.companyId == this.filter.companyId;
          });
        }
        this.loadTable(this.page, false);
      });
    });


    tabletService.getAll().then((data) => {
      this.users = data;
    });
  }

  ngOnInit() {
    //this.loadTable(this.page);
    //this.init = true;
    var page = JSON.parse(this.storageService.get("project_inspections_page"));
    var filter = JSON.parse(this.storageService.get("project_inspections_filters"));
    if (page != undefined) {
      this.page = page;
      console.log(this.page);
    }
    if (filter != undefined) {
      this.filter = filter;
      this.filter.start_Date = moment(this.filter.start_Date).toDate();
      this.filter.end_Date = moment(this.filter.end_Date).toDate();
    }
    if (page == undefined && filter == undefined) {
      console.log("no filters");
      this.setPage({ offset: 0 });
      this.init = true;
      this.resetFilters();
    } else {
      this.currentPageLimit = this.page.size;
      this.loadTable(this.page, false);
    }
  }
  resetFilters() {
    this.filter = {
      companyId: 0,
      locationId: 0,
      pdaUserId: 0,
      date_Range: 1,
      start_Date: moment().add(-1, 'months').toDate(),
      end_Date: moment().toDate(),
      quickSearch: '',
      job_No: ''
    };
    if (this.is_client) {
      this.filter.companyId = this.user.companyId;
    }
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.currentPageLimit = this.page.size;
  }

  onCompanyChange(value) {
    this.filter.locationId = 0;
    if (value == 0) { value = null; }

    this.locations = this.locationList.filter(c => {
      return value == null || c.companyId == value;
    });
  }


  // ###################################
  // TABLE
  // ###################################
  public onLimitChange(limit: any): void {
    this.page.size = parseInt(limit, 10);
    this.loadTable(this.page, false);
  }
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page, false);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page,false);
  }

  loadTable(page: Page, clicked) {
    //this should always be the case but just incase
    if (this.is_client) {
      this.filter.companyId = this.user.companyId;
    }
    if (clicked) {
      this.page.pageNumber = 0;
      this.currentPageLimit = this.page.size;
      this.setPage({ offset: 0 });
      return;
    }
    this.storageService.dataSave("installation_inspections_filters", JSON.stringify(this.filter));
    this.storageService.dataSave("installation_inspections_page", JSON.stringify(this.page));
    if (this.filter.companyId == null || this.filter.companyId == undefined) {
      this.filter.companyId = 0;
    }
    if (this.filter.locationId == null || this.filter.locationId == undefined) {
      this.filter.locationId = 0;
    }
    this.projectInspectionService.getResults(this.page, this.filter.companyId, this.filter.locationId,
      this.filter.pdaUserId, this.filter.start_Date, this.filter.end_Date,  this.filter.quickSearch, this.filter.job_No).then((pagedData) => {
        this.page = pagedData.page;
        this.rows = pagedData.data;
        console.log(this.rows);
        this.refreshbasketrow();
        if (this.init) {
          this.gotoTop();
          this.init = false;
        }
      });
    this.getcolumns();
  }

  getcolumns() {
    this.columnService.getColumns(this.authService.currentUser.value.userId, "projectinspections").then((data) => {
      if (data.columns != null) {
        this.columns = this.columns.filter(c => {
          return data.columns.includes(c.name);
        });
        this.toggleOpen = false;
      }

    });
  }
  SaveSelection() {
    let result = this.columns.map(a => a.name);
    this.columnService.save(this.authService.currentUser.value.userId, "projectinspections", result).then((data) => {
    });
  }

  downloadReport(id: String) {
    this.reportableService.getProjectExam(id).then((data) => {
      importedSaveAs(data, 'project Report.pdf');
    });
  }



  downloadERSTR(id: number) {
    this.reportableService.getERSTR(id).then((data) => {
      importedSaveAs(data, 'ERSTR.pdf');
    });
  }

  dateChange(ev) {
    if (ev == -1) {
      return;
    }

    let date = moment();
    if (ev == -2) {
      date.set('year', 2010);
      date.set('month', 0);
      date.set('date', 1);
    }
    else {
      if (ev == 0) {
        date = date.subtract(1, 'weeks');
      } else {
        date = date.subtract(ev, 'months');
      }
    }

    let jsDate = date.toDate();
    //var startDate: NgbDateStruct = { year: jsDate.getFullYear(), month: jsDate.getMonth() + 1, day: jsDate.getDate() };
    this.filter.start_Date = jsDate;
  }
  //Basket
  //getImages(inspectionId) {
  //  this.serviceinspectionService.getImages(inspectionId).then((data) => {
  //    importedSaveAs(data, 'Images.zip');
  //  });
  //}

  addToBasket(item, type) {
    if (type == 1)
      this.basketService.addROTEtoBasket(item);
    else if (type == 2)
      this.basketService.addServicetoBasket(item);
    else if (type == 3)
      this.basketService.addRAMStoBasket(item);
    else if (type == 4)
      this.basketService.addESTRtoBasket(item);
    else if (type == 5)
      this.basketService.addInstallationtoBasket(item);
    else if (type == 13)
      this.basketService.addProjecttoBasket(item);

  }

  removeFromBasket(item, type) {
    if (type == 1)
      this.basketService.removeROTE(item);
    else if (type == 2)
      this.basketService.removeService(item);
    else if (type == 3)
      this.basketService.removeRAMS(item);
    else if (type == 4)
      this.basketService.removeESTR(item);
    else if (type == 5)
      this.basketService.removeInstallation(item);
    else if (type == 13)
      this.basketService.removeProject(item);

  }

  resetItem(item) {
    item.addedrote = false;
    item.addedrams = false;
    item.addedestr = false;
    item.addedinstallation = false;
    item.addedproject = false;
  }

  resetItems() {
    for (let item of this.rows) {
      this.resetItem(item);
    }
  }

  basketCompleted(data) {
    this.resetItems();
  }

  basketClearing(data) {
    this.resetItems();
  }

  addAllToBasket() {
    if (this.filter.companyId == null || this.filter.companyId == undefined) {
      this.filter.companyId = 0;
    }
    if (this.filter.locationId == null || this.filter.locationId == undefined) {
      this.filter.locationId = 0;
    }
    this.projectInspectionService.getAll(this.page, this.filter.companyId, this.filter.locationId,
      this.filter.pdaUserId, this.filter.start_Date, this.filter.end_Date, this.filter.quickSearch, this.filter.job_No).then((pagedData) => {
        for (let i = 0; i < pagedData.data.length; i++) {
          const jsonObj = pagedData.data[i];
          console.log(jsonObj);
          if (jsonObj.id != null) {
            this.addToBasket(jsonObj, 13);
          }
        }
        this.refreshbasketrow();
      });
  }

  generateExcel() {
    this.projectInspectionService.getExcel(this.filter.companyId, this.filter.locationId,
      this.filter.pdaUserId, this.filter.start_Date, this.filter.end_Date, this.filter.quickSearch, this.filter.job_No).then((data) => {
        importedSaveAs(data, 'Report.xlsx');
      });
  }

  refreshbasketrow() {
    console.log("REFRESHING");
    var items = this.basketService.getitems();
    for (let i = 0; i < (this.rows.length); i++) {
      let row = this.rows[i];
      for (let k = 0; k < (items.value.length); k++) {
        let item = items.value[k];
        if (item.report_Type == 1) { //ROTE
          if ((item.description == row.description) && (item.serial_Num == row.serial_Num)) {
            this.rows[i].addedrote = true;
          }
        } else if (item.report_Type == 2) { //SERVICE
          if ((item.description == row.description) && (item.serial_Num == row.serial_Num)) {
            this.rows[i].addedservice = true;
          }
        } else if (item.report_Type == 3) { //RAMS
          if ((item.guid == row.ramS_Id) || (item.guid == row.location_Inspection_Id)) {
            this.rows[i].addedrams = true;
          }
        } else if (item.report_Type == 4) { //ERSTR
          if ((item.description == row.engineer) && (item.serial_Num == row.job_No)) {
            this.rows[i].addedestr = true;
          }
        } else if (item.report_Type == 5) { //Install
          if ((item.description == row.description) && (item.serial_Num == row.serial_Num)) {
            this.rows[i].addedinstallation = true;
          }
        } else if (item.report_Type == 13) { //Project
          if ((item.guid == row.id)) {
            this.rows[i].addedproject = true;
          }
        }
      }

    }
  }


  basketOnclickEvent(ev) {
    console.log(ev);
    console.log("CLICKED");
    //   this.refreshbasketrow();
    for (let i = 0; i < (this.rows.length); i++) {
      let row = this.rows[i];
      if (ev.report_Type == 1) { //ROTE
        if ((ev.description == row.description) && (ev.serial_Num == row.serial_Num)) {
          this.rows[i].addedrote = false;
        }
      } else if (ev.report_Type == 2) { //SERVICE
        if ((ev.description == row.description) && (ev.serial_Num == row.serial_Num)) {
          this.rows[i].addedservice = false;
        }
      } else if (ev.report_Type == 3) { //RAMS
        if ((ev.guid == row.ramS_Id) || (ev.guid == row.location_Inspection_Id)) {
          this.rows[i].addedrams = false;
        }
      } else if (ev.report_Type == 4) { //ERSTR
        if ((ev.description == row.engineer) && (ev.serial_Num == row.job_No)) {
          this.rows[i].addedestr = false;
        }
      } else if (ev.report_Type == 5) { //Install
        if ((ev.description == row.description) && (ev.serial_Num == row.serial_Num)) {
          this.rows[i].addedinstallation = false;
        }
      } else if (ev.report_Type == 13) { //Project
        if ( (ev.guid == row.id)) {
          this.rows[i].addedproject = false;
        }
      }
    }
  }
  gotoTop() {
    console.log("GOTO TOP");
    // window.scrollTo(-9999,-99999);
    let top = document.getElementById('top');
    console.log(top);
    if (top !== null) {
      top.scrollIntoView(true);
      //  document.getElementById("top").scrollTop -= 99999;
      top = null;
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { PagedData } from '../models/paging/paged-data';
import { Page } from '../models/paging/page';
import { Company } from '../models/company';
import { ServerData } from '../models/paging/server-data';

import { SnotifyService } from 'ng-snotify';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { ApiResponse } from '../models/api-response';



@Injectable()
export class CompanyService {
  constructor(private http: HttpClient, private snotifyService: SnotifyService,private authService: AuthService) { }
  //public user = JSON.parse(localStorage.getItem("currentUser"));
    
  
  public getAll(): Promise<Company[]> {
    const headers = {
      'Content-Type': 'application/json',
    };
    
    return new Promise((resolve, reject) => {
      this.http.get<Company[]>(environment.apiUrl + 'company/getall', { headers: headers }).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        /*reject();*/
        resolve(null)
      });
    });
  }

  public get(id: Number): Promise<Company> {
    const headers = {
      'Content-Type': 'application/json',
    };
    return new Promise((resolve, reject) => {
      this.http.get<Company>(environment.apiUrl + 'company/get?companyId=' + id, { headers: headers }).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public save(company: Company): Promise<ApiResponse> {
    return new Promise((resolve, reject) => {
      const headers = {
        'Content-Type': 'application/json',
      };
      console.log("company service");
      console.log(company);
      this.http.post<ApiResponse>(environment.apiUrl + 'company/post', company, { headers: headers }).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });

      //if (company.is_edit) {
      //  //this.http.put<Company>('/api/company/put', company).subscribe(data => {
      //  //  resolve(data);
      //  //  this.snotifyService.success('Successfully updated company');
      //  //}, error => {
      //  //  console.log(error);
      //  //  reject();
      //  //});
      //} else {
      //  this.http.post<ApiResponse>(environment.apiUrl + 'api/company/post', company, { headers: headers }).subscribe(data => {
      //    resolve(data);
      //    this.snotifyService.success('Successfully added company');
      //  }, error => {
      //    console.log(error);
      //    reject();
      //  });
      //}
    });
  }


  public getResults(page: Page, name: string, quickSearch: string): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'CompanyName'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'asc'; }

      let params = '&name=' + name;
      params += '&quickSearch=' + quickSearch;
      this.http.get<ServerData>(environment.apiUrl + 'company/gettable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params, { headers: headers }).subscribe(data => {
        page.totalElements = data.total;
        page.totalPages = page.totalElements / page.size;
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }

  public getExcel(name: string, quickSearch: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let params = '?name=' + name;
      params += '&quickSearch=' + quickSearch;

      this.http.get<any>(environment.apiUrl + 'company/getexcel' + params).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/vnd.ms-excel');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }



  converBase64toBlob(content, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = window.atob(content); //method which converts base64 to binary
    let byteArrays = [
    ];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

//TODO: Change this so what we use environment variables

@Injectable()
export class ReportableService {
  constructor(private http: HttpClient) { }

  public getRAMSReport(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'getriskassessment?location_Inspection_ID=' + id).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  public getAssetRegister(id: String): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'getassetregister?id=' + id).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  public getLiftingExam(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'getliftingexam?inspection_id=' + id).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  public getChecklist(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'GetChecklist?inspection_Id=' + id).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  public getVehicleInspection(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'getVehicleInspection?inspection_id=' + id).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getServiceExam(id: number, is_Client: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'getservice?inspection_Id=' + id + '&is_Client=' + is_Client).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getInstallationExam(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'getinstallation?inspection_Id=' + id).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  public getProjectExam(id: String): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'getprojectexam?inspection_Id=' + id).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getERSTR(id: number): Promise<any> {
    return new Promise((resolve, reject) => {

      this.http.get<any>(environment.reportableUrl + 'geterstr?engineer_report_id=' + id).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getERSTRContractor(id: number): Promise<any> {
    return new Promise((resolve, reject) => {

      this.http.get<any>(environment.reportableUrl + 'geterstrcontractor?engineer_Report_Id=' + id).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  public getBreakdown(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'getbreakdown?inspection_Id=' + id).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  public getJobForm(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'getjobform?location_inspection_Id=' + id).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getTimesheet(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'gettimesheet?Id=' + id).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  public getTimesheetReport(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'gettimesheetReport?Id=' + id).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  public getClientTimesheetReport(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'getclienttimesheetReport?Id=' + id).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }


  public getTimesheetsummary(start_Date: string, end_Date: string, Pda_UserId: number): Promise<any> {
    console.log(start_Date);
    console.log(end_Date);
    console.log(Pda_UserId);

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.reportableUrl + 'gettimesheetsummary?start_Date=' + start_Date + '&end_Date=' + end_Date + '&pdaUserId=' + Pda_UserId).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getFile(filePath: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'equipment/getfile?path=' +  filePath).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/pdf');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }


  converBase64toBlob(content, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = window.atob(content); //method which converts base64 to binary
    let byteArrays = [
    ];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }
}

import { Injectable } from '@angular/core';
import { Basket_Item } from '../models/Basket_Item';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { SnotifyService } from 'ng-snotify';
import { ConfirmationService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class BasketService {

  //We have an observable so that we can subscribe to the results;

  basketItems: BehaviorSubject<Array<Basket_Item>>;
  dataSource: Array<Basket_Item>;

  constructor(private http: HttpClient) {
    this.dataSource = new Array<Basket_Item>();
    this.basketItems = new BehaviorSubject<Array<Basket_Item>>(this.dataSource);

  }

  public add(item: Basket_Item) {
    console.log(item);
    if (item.id == -1) {
      if (this.dataSource.some(x => x.guid === item.guid && x.report_Type == item.report_Type))
        return;
    } else {
      if (this.dataSource.some(x => x.id === item.id && x.report_Type == item.report_Type))
        return;
    }
    this.dataSource.push(item);
    this.basketItems.next(this.dataSource);
  }

  public getitems() {
    return this.basketItems;
  }
  public remove(item: Basket_Item) {
    const index: number = this.dataSource.indexOf(item);
    console.log(index);
    if (index !== -1) {
      this.dataSource.splice(index, 1);
    }
    this.basketItems.next(this.dataSource);
  }

  public clear() {
    this.dataSource = new Array<Basket_Item>();
    this.basketItems.next(this.dataSource);
  }

  public getZip(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + 'inspection/PostZip', this.dataSource).subscribe(data => {
        var blob = this.convertBase64toBlob(data, 'application/zip');
        resolve(blob)
        this.clear();
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public emailZip(email): Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };
    var dto = {
      items: this.dataSource,
      email: email
    };
    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + 'inspection/EmailZip', dto, { headers: headers }).subscribe(data => {
        //var blob = this.convertBase64toBlob(data, 'application/zip');
        //resolve(blob)
        this.clear();
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getBatchReport(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + 'inspection/PostBatch', this.dataSource).subscribe(data => {
        let blob = this.convertBase64toBlob(data, 'application/pdf');
        resolve(blob);
        this.clear();
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  convertBase64toBlob(content, contentType) {
    contentType = contentType || '';
    var sliceSize = 512;
    var byteCharacters = window.atob(content); //method which converts base64 to binary
    var byteArrays = [
    ];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }

  addROTEtoBasket(item) {
    let basketItem = new Basket_Item();
    basketItem.id = item.id;
    basketItem.type = "ROTE";
    basketItem.description = item.description;
    basketItem.serial_Num = item.serial_Num;
    basketItem.category_Name = item.category_Name;
    basketItem.is_Annual = item.is_Annual;
    basketItem.category_Id = item.category_Id;
    basketItem.report_Type = 1;
    basketItem.safe = item.safe_For_Use;
    console.log(basketItem);
    this.add(basketItem);
    item.addedrote = true;
  }

  addServicetoBasket(item) {
    let basketItem = new Basket_Item();
    basketItem.id = item.id;
    basketItem.type = "Service";
    basketItem.guid = '';
    basketItem.description = item.description;
    basketItem.serial_Num = item.serial_Num;
    basketItem.category_Name = item.company.name;
    basketItem.is_Annual = item.is_Annual;
    basketItem.category_Id = 0;
    basketItem.report_Type = 2;
    basketItem.safe = item.safe_For_Use;

    this.add(basketItem);
    item.addedservice = true;
  }
  addRAMStoBasket(item) {
    let basketItem = new Basket_Item();
    basketItem.id = -1;
    if(item.ramS_Id != undefined)
      basketItem.guid = item.ramS_Id;
    else if (item.location_Inspection_Id != undefined)
      basketItem.guid = item.location_Inspection_Id;
    basketItem.type = "RAMS";
    basketItem.description = item.engineer;
    basketItem.serial_Num = item.job_No;
    basketItem.category_Name = " ";
    basketItem.is_Annual = false;
    basketItem.category_Id = 0;
    basketItem.report_Type = 3;
    basketItem.safe = true;
    this.add(basketItem);
    item.addedrams = true;
  }

  addESTRtoBasket(item) {
    let basketItem = new Basket_Item();
    basketItem.id = item.engineer_Report_Id;
    basketItem.guid = '';
    basketItem.type = "ERSTR";
    basketItem.description = item.engineer;
    basketItem.serial_Num = item.job_No;
    basketItem.category_Name = " ";
    basketItem.is_Annual = false;
    basketItem.category_Id = 0;
    basketItem.report_Type = 4;
    basketItem.safe = true;

    this.add(basketItem);
    item.addedestr = true;
  }

  addVehicletoBasket(item) {
    let basketItem = new Basket_Item();
    basketItem.id = item.id;
    basketItem.guid = '';
    basketItem.type = "Vehicle";
    basketItem.description = item.engineer;
    basketItem.serial_Num = item.job_No;
    basketItem.category_Name = " ";
    basketItem.is_Annual = false;
    basketItem.category_Id = 0;
    basketItem.report_Type = 9;
    basketItem.safe = true;

    this.add(basketItem);
    item.addedvehicle = true;
  }
  addClientServicetoBasket(item) {
    let basketItem = new Basket_Item();
    basketItem.id = item.id;
    basketItem.guid = '';
    basketItem.type = "CLIENT SERVICE";
    basketItem.description = item.engineer;
    basketItem.serial_Num = item.job_No;
    basketItem.category_Name = " ";
    basketItem.is_Annual = false;
    basketItem.category_Id = 0;
    basketItem.report_Type = 7;
    basketItem.safe = true;

    this.add(basketItem);
    item.addedclientservice = true;
  }

  addChecklisttoBasket(item) {
    let basketItem = new Basket_Item();
    basketItem.id = item.id;
    basketItem.guid = '';
    basketItem.type = "CHECKLIST";
    basketItem.description = item.description;
    basketItem.serial_Num = item.serial_Num;
    basketItem.category_Name = " ";
    basketItem.is_Annual = false;
    basketItem.category_Id = 0;
    basketItem.report_Type = 6;
    basketItem.safe = true;

    this.add(basketItem);
    item.addedchecklist = true;
  }

  addInstallationtoBasket(item) {
    let basketItem = new Basket_Item();
    basketItem.id = item.id;
    basketItem.guid = '';
    basketItem.type = "INSTALLATION";
    basketItem.description = item.description;
    basketItem.serial_Num = item.serial_Num;
    basketItem.category_Name = " ";
    basketItem.is_Annual = false;
    basketItem.category_Id = 0;
    basketItem.report_Type = 5;
    basketItem.safe = true;

    this.add(basketItem);
    item.addedinstallation = true;
  }
  addProjecttoBasket(item) {
    console.log("adding project");
    console.log(item);
    let basketItem = new Basket_Item();
    basketItem.id = -1;
    basketItem.guid = item.id;
    basketItem.type = "PROJECT";
    basketItem.description = item.job_No;
    basketItem.serial_Num = '';
    basketItem.category_Name = " ";
    basketItem.is_Annual = false;
    basketItem.category_Id = 0;
    basketItem.report_Type = 10;
    basketItem.safe = true;

    this.add(basketItem);
    item.addedproject = true;
  }

  addBreakdowntoBasket(item) {
    let basketItem = new Basket_Item();
    basketItem.id = item.id;
    basketItem.type = "Breakdown";
    basketItem.description = item.description;
    basketItem.serial_Num = item.serial_Num;
    basketItem.category_Name = item.category_Name;
    basketItem.is_Annual = item.is_Annual;
    basketItem.category_Id = item.category_Id;
    basketItem.report_Type = 8;
    basketItem.safe = item.safe_For_Use;
    console.log(basketItem);
    this.add(basketItem);
    item.addedbreakdown = true;
  }

  removeROTE(item) {
    var basketItem = new Basket_Item();
    var items = this.getitems();
    console.log(items.value);
    for (let i = 0; i < (items.value.length); i++) {
      console.log(items.value[i]);
      if ((items.value[i].description == item.description) && (items.value[i].serial_Num == item.serial_Num) && (items.value[i].report_Type == 1)) {
        console.log("MATCHES");
        this.remove(items.value[i]);
      }
    }
    item.addedrote = false;
  }

  removeService(item) {
    var basketItem = new Basket_Item();
    var items = this.getitems();
    console.log(items.value);

    for (let i = 0; i < (items.value.length); i++) {
      console.log(items.value[i]);
      if ((items.value[i].description == item.description) && (items.value[i].serial_Num == item.serial_Num)) {
        console.log("MATCHES");
        this.remove(items.value[i]);
      }
    }
    item.addedservice = false;
  }

  removeRAMS(item) {
    var basketItem = new Basket_Item();
    var items = this.getitems();
    console.log(items.value);

    for (let i = 0; i < (items.value.length); i++) {
      console.log(items.value[i]);
      if ((items.value[i].description == item.engineer) && (items.value[i].serial_Num == item.job_No) && (items.value[i].report_Type == 3)) {
        console.log("MATCHES");
        this.remove(items.value[i]);
      }
    }
    item.addedrams = false;
  }

  removeESTR(item) {
    var basketItem = new Basket_Item();
    var items = this.getitems();
    for (let i = 0; i < (items.value.length); i++) {
      console.log(items.value[i]);
      if ((items.value[i].description == item.engineer) && (items.value[i].serial_Num == item.job_No) && (items.value[i].report_Type == 4)) {
        this.remove(items.value[i]);
      }
    }
    item.addedestr = false;
  }
  removeVehicle(item) {
    var basketItem = new Basket_Item();
    var items = this.getitems();
    for (let i = 0; i < (items.value.length); i++) {
      console.log(items.value[i]);
      if ((items.value[i].description == item.engineer) && (items.value[i].serial_Num == item.job_No) && (items.value[i].report_Type == 9)) {
        this.remove(items.value[i]);
      }
    }
    item.addedvehicle = false;
  }
  removeChecklist(item) {
    var basketItem = new Basket_Item();
    var items = this.getitems();
    for (let i = 0; i < (items.value.length); i++) {
      console.log(items.value[i]);
      if ((items.value[i].description == item.engineer) && (items.value[i].serial_Num == item.job_No) && (items.value[i].report_Type == 6)) {
        this.remove(items.value[i]);
      }
    }
    item.addedchecklist = false;
  }
  removeInstallation(item) {
    var basketItem = new Basket_Item();
    var items = this.getitems();
    for (let i = 0; i < (items.value.length); i++) {
      console.log(items.value[i]);
      if ((items.value[i].description == item.engineer) && (items.value[i].serial_Num == item.job_No) && (items.value[i].report_Type == 5)) {
        this.remove(items.value[i]);
      }
    }
    item.addedinstallation = false;
  }
  removeProject(item) {
    console.log("removing project");
    console.log(item);
    var basketItem = new Basket_Item();
    var items = this.getitems();
    for (let i = 0; i < (items.value.length); i++) {
      console.log(items.value[i]);
      if ((items.value[i].guid == item.id) && (items.value[i].report_Type == 10)) {
        this.remove(items.value[i]);
      }
    }
    item.addedproject = false;
  }

  removeClientService(item) {
    var basketItem = new Basket_Item();
    var items = this.getitems();
    for (let i = 0; i < (items.value.length); i++) {
      console.log(items.value[i]);
      if ((items.value[i].description == item.engineer) && (items.value[i].serial_Num == item.job_No) && (items.value[i].report_Type == 7) && (items.value[i].id == item.id)) {
        this.remove(items.value[i]);
      }
    }
    item.addedclientservice = false;
  }

  removeBreakdown(item) {
    var basketItem = new Basket_Item();
    var items = this.getitems();
    console.log(items.value);
    for (let i = 0; i < (items.value.length); i++) {
      console.log(items.value[i]);
      if ((items.value[i].description == item.description) && (items.value[i].serial_Num == item.serial_Num) && (items.value[i].report_Type == 8)) {
        console.log("MATCHES");
        this.remove(items.value[i]);
      }
    }
    item.addedbreakdown = false;
  }
}

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { ERSTRService } from '../../../services/erstr.service';
import { Page } from '../../../models/paging/page';
import * as moment from 'moment';
import { Tablet } from '../../../models/tablet';
import { TabletService } from '../../../services/tablet.service';
import { Router } from '@angular/router';
import { ReportableService } from '../../../services/reportable.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { ConfirmationService } from 'primeng/api';
import { FilterService } from '../../../services/filter.service';
import { Basket_Item } from '../../../models/Basket_Item';
import { BasketService } from '../../../services/basket.service';

@Component({
  selector: 'app-erstr',
  templateUrl: './erstr.component.html',
  styleUrls: ['./erstr.component.css']
})
export class ErstrComponent implements OnInit {

  users: Tablet[];
  welders = [];
    init:boolean;
 

  filter: any = {
    tabletUser_Id: '',
    applicationUser_Id: '',
    date_Range: 1,
    start_Date: moment().add(-1, 'months').toDate(),
    end_Date: moment().toDate(),
    job_No: "",
    job_Type: "0",
    company_Id: 0,
    location_Id: 0,
  };
  default_filters = JSON.parse(JSON.stringify(this.filter));

  page = new Page();
  rows = Array<any>();
  is_welder = false;

  is_client = false;

  constructor(private authService: AuthService, private erstrService: ERSTRService, tabletService: TabletService, private router: Router,
    private reportableService: ReportableService, private confirmationService: ConfirmationService, private filterService: FilterService,
    public basketService: BasketService) {
    this.is_welder = this.authService.currentUser.value.userType == 3;
    this.is_client = authService.currentUser.value.userType == 0;
    
    if (this.is_client) {
      this.filter.company_Id = authService.currentUser.value.company_Id;
      this.filter.location_Id = authService.currentUser.value.location_Id;
    }

    if (this.is_welder)
      this.filter.applicationUser_Id = this.authService.currentUser.value.id;

    this.page.pageNumber = 0;
    this.page.size = 10;
    this.page.sortOrder = 'desc';
    this.page.sortBy = "Submitted_At";

    tabletService.getAll().then((data) => {
      this.users = data;
    });

    

    // Check if any filters are present
    var filters = filterService.getFilters(authService.currentUser.value.id, this.router.url);
    if (filters != null)
      this.filter = filters;
  }

  ngOnInit() {
    this.loadTable(this.page);
      this.init = true;
    
  }

  resetFilters() {
    this.filter = this.filterService.parseFilters(JSON.stringify(this.default_filters));
    this.loadTable(this.page);
  }


  onEngineerChanged(value) {
    if (value != "") {
      this.filter.applicationUser_Id = "";
    }
  }


  onWelderChanged(value) {
    if (value != "") {
      this.filter.tabletUser_Id = "";
    }
  }

  view(row) {
    this.router.navigate(['reports/erstr/manage'], { queryParams: { id: row.id, editing: true } });
  }

  remove(row) {    
    this.confirmationService.confirm({
      message: 'Are you sure that you want remove the ERSTR for job:</br><br/>' + row.job_No,
      accept: () => {
        this.erstrService.delete(row.id).then(() => {
          this.loadTable(this.page);
        });
      }
    });
  }


  // ###################################
  // TABLE
  // ###################################
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page);
  }

  loadTable(page: Page) {
    this.filterService.saveFilters(this.authService.currentUser.value.id, this.router.url, this.filter);
    this.erstrService.getResults(this.page, this.filter.tabletUser_Id, this.filter.applicationUser_Id, this.filter.start_Date, this.filter.end_Date, this.filter.job_No, this.filter.job_Type, this.filter.company_Id, this.filter.location_Id).then((pagedData) => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
      console.log(this.rows);
      this.refreshbasketrow();
      if (this.init) {
        this.gotoTop();
        this.init = false;
      }
    });
  }

  dateChange(ev) {
    if (ev == -1) {
      return;
    }

    let date = moment();
    if (ev == 0) {
      date = date.subtract(1, 'weeks');
    } else {
      date = date.subtract(ev, 'months');
    }

    let jsDate = date.toDate();
    //var startDate: NgbDateStruct = { year: jsDate.getFullYear(), month: jsDate.getMonth() + 1, day: jsDate.getDate() };
    this.filter.start_Date = jsDate;
  }

  download(engineer_Report_Id: number, location_Inspection_Id: string, job_Type: number) {
    if (job_Type == 5) {
      this.reportableService.getERSTRContractor(engineer_Report_Id).then((data) => {
        importedSaveAs(data, 'ERSTR.pdf');
      });
    } else {
      console.log(engineer_Report_Id);
      this.reportableService.getERSTR(engineer_Report_Id).then((data) => {
        importedSaveAs(data, 'ERSTR.pdf');
      });
    } 
  }
  //Basket
  addToBasket(item, type) {
    if (type == 1)
      this.basketService.addROTEtoBasket(item);
    else if (type == 2)
      this.basketService.addRAMStoBasket(item);
    else if (type == 3)
      this.basketService.addRAMStoBasket(item);
    else if (type == 4)
      this.addESTRtoBasket(item);

  }

  removeFromBasket(item, type) {
    if (type == 1)
      this.basketService.removeROTE(item);
    else if (type == 2)
      this.basketService.removeRAMS(item);
    else if (type == 3)
      this.basketService.removeRAMS(item);
    else if (type == 4)
      this.basketService.removeESTR(item);

  }
  addESTRtoBasket(item) {
    let basketItem = new Basket_Item();
    basketItem.id = item.id;
    basketItem.guid = '';
    basketItem.description = item.engineer;
    basketItem.serial_Num = item.job_No;
    basketItem.category_Name = " ";
    basketItem.is_Annual = false;
    basketItem.type = "ERSTR";
    basketItem.safe = false;
    basketItem.category_Id = 0;
    basketItem.report_Type = 4;

    this.basketService.add(basketItem);
    item.addedestr = true;
  }

  resetItem(item) {
    item.addedrote = false;
    item.addedrams = false;
    item.addedestr = false;
  }
  resetItems() {
    for (let item of this.rows) {
      this.resetItem(item);
    }
  }

  basketCompleted(data) {
    this.resetItems();
  }

  basketClearing(data) {
    this.resetItems();
  }
  addAllToBasket() {
    this.erstrService.getAll(this.page, this.filter.tabletUser_Id, this.filter.applicationUser_Id, this.filter.start_Date, this.filter.end_Date, this.filter.job_No, this.filter.job_Type, this.filter.company_Id, this.filter.location_Id).then((pagedData) => {
      for (let i = 0; i < pagedData.length; i++) {
        const jsonObj = pagedData[i];
        console.log(jsonObj);
        if (jsonObj.id != null) {
          if (jsonObj.ramS_Id != null && jsonObj.ramS_Id != undefined)
            this.addToBasket(jsonObj, 3);
          if (jsonObj.id != null && jsonObj.id != undefined)
            this.addToBasket(jsonObj, 4);
          if (jsonObj.next_Inspection_Date != 'N/A' && jsonObj.next_Inspection_Date != undefined)
            this.addToBasket(jsonObj, 1);
        }
      }
      this.refreshbasketrow();
    });
  }
  generateExcel() {
    this.erstrService.getExcel(this.page, this.filter.tabletUser_Id, this.filter.applicationUser_Id, this.filter.start_Date, this.filter.end_Date, this.filter.job_No, this.filter.job_Type, this.filter.company_Id, this.filter.location_Id).then((data) => {
      importedSaveAs(data, 'Report.xlsx');
    });
  }
  refreshbasketrow() {
    console.log("REFRESHING");
    var items = this.basketService.getitems();
    for (let i = 0; i < (this.rows.length); i++) {
      let row = this.rows[i];
      for (let k = 0; k < (items.value.length); k++) {
        let item = items.value[k];
        if (item.report_Type == 1) { //ROTE
          if ((item.description == row.description) && (item.serial_Num == row.serial_Num)) {
            this.rows[i].addedrote = true;
          }
        } else if (item.report_Type == 2) { //SERVICE
          if ((item.description == row.description) && (item.serial_Num == row.serial_Num)) {
            this.rows[i].addedservice = true;
          }
        } else if (item.report_Type == 3) { //RAMS
          if ((item.guid == row.ramS_Id) || (item.guid == row.location_Inspection_Id)) {
            this.rows[i].addedrams = true;
          }
        } else if (item.report_Type == 4) { //ERSTR
          if ((item.description == row.engineer) && (item.serial_Num == row.job_No)) {
            this.rows[i].addedestr = true;
          }
        }
      }

    }
  }


  basketOnclickEvent(ev) {
    console.log(ev);
    console.log("CLICKED");
    //   this.refreshbasketrow();
    for (let i = 0; i < (this.rows.length); i++) {
      let row = this.rows[i];
      if (ev.report_Type == 1) { //ROTE
        if ((ev.description == row.description) && (ev.serial_Num == row.serial_Num)) {
          this.rows[i].addedrote = false;
        }
      } else if (ev.report_Type == 2) { //SERVICE
        if ((ev.description == row.description) && (ev.serial_Num == row.serial_Num)) {
          this.rows[i].addedservice = false;
        }
      } else if (ev.report_Type == 3) { //RAMS
        if ((ev.guid == row.ramS_Id) || (ev.guid == row.location_Inspection_Id)) {
          this.rows[i].addedrams = false;
        }
      } else if (ev.report_Type == 4) { //ERSTR
        if ((ev.description == row.engineer) && (ev.serial_Num == row.job_No)) {
          this.rows[i].addedestr = false;
        }
      }
    }
  }
  gotoTop() {
    console.log("GOTO TOP");
    // window.scrollTo(-9999,-99999);
    let top = document.getElementById('top');
    console.log(top);
    if (top !== null) {
      top.scrollIntoView(true);
      //  document.getElementById("top").scrollTop -= 99999;
      top = null;
    }
  }
}


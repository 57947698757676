import { Component, OnInit, ViewChild } from '@angular/core';
import { Company } from '../../../models/company';
import { Location } from '../../../models/location';

import { saveAs as importedSaveAs } from 'file-saver';
import * as moment from 'moment';
import { Page } from '../../../models/paging/page';
import { AuthService } from '../../../services/auth.service';
import { CompanyService } from '../../../services/company.service';
import { LocationService } from '../../../services/location.service';
import { ReportableService } from '../../../services/reportable.service';
import { AssetRegisterService } from '../../../services/assetregister.service';
import { Router } from '@angular/router';
import { FilterService } from '../../../services/filter.service';
import { Basket_Item } from '../../../models/Basket_Item';
import { BasketService } from '../../../services/basket.service';
import { ColumnService } from '../../../services/column.service';
import { StorageService } from '../../../services/storage.service';
import { CategoryService } from '../../../services/category.service';
import { Category } from '../../../models/category';



@Component({
  selector: 'app-assetregister',
  templateUrl: './assetregister.component.html',
  styleUrls: ['./assetregister.component.css']
})

export class AssetRegisterComponent implements OnInit {
  companies: Company[];
  locations: Location[];
  locationList: Location[];
  categories: Category[];
  toggleOpen = false;
  user;

  filter: any = {
    companyId: 0,
    locationId: 0,
    date_Range: 1,
    start_Date: moment().add(-1, 'months').toDate(),
    end_Date: moment().toDate(),
    serial_Num: '',
    internal_Location: '',
    quickSearch: '',
    status: -1,
    job_No: '',
    decommissioned: false,
    category_Id: 0
  };
  default_filters = JSON.parse(JSON.stringify(this.filter));

  page = new Page();
  rows = Array<any>();
  limit = 10;

  is_client = false;
  client;
  init: boolean;

  public readonly pageLimitOptions = [
    { value: 5 },
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ];


  public currentPageLimit: number = 10;

  columns = [
    { name: 'Last Inspection Date', prop:'inspected_At_Formatted' },
    { name: 'Next Inspection Date', prop: 'next_Inspection_Date_Formatted' },
    { name: 'Description', prop: 'description' },
    { name: 'Serial Num', prop: 'serial_Num' },
    { name: 'SWL', prop: 'swl' },
    { name: 'Asset #', prop: 'asset_Num' },
    { name: 'Category', prop: 'category' },
    { name: 'Other Details', prop: 'other_Details' },
    { name: 'Internal Location', prop: 'internal_Location' },
    { name: 'Date of Manufacturer', prop: 'date_Of_Manuf_Formatted' },
    { name: 'Decommissioned', prop: 'decommissioned_Formatted' },
    { name: 'Inspection Job Number', prop: 'job_No' },
    { name: 'Company', prop: 'company' },
    { name: 'Location', prop: 'location' },
    { name: 'Inspection Status', prop: 'state_Formatted' },
    { name: 'Service Date', prop: 'service_Inspection_Date_Formatted' },
    { name: 'Next Service Date', prop: 'service_Next_Inspection_Date_Formatted' },
    { name: 'Service Job No', prop: 'service_Job_No' },
    { name: 'Service status', prop: 'service_Safe_For_Use_Formatted' },
    { name: 'Breakdown Date', prop: 'breakdown_Inspection_Date_Formatted' },
    { name: 'Breakdown Job No', prop: 'breakdown_Job_No' },
    { name: 'Installation Date', prop: 'install_Inspection_Date_Formatted' },
    { name: 'Installation Job No', prop: 'install_Job_No' },

  ];

  allColumns = [
    { name: 'Last Inspection Date', prop: 'inspected_At_Formatted' },
    { name: 'Next Inspection Date', prop: 'next_Inspection_Date_Formatted' },
    { name: 'Description', prop: 'description' },
    { name: 'Serial Num', prop: 'serial_Num' },
    { name: 'SWL', prop: 'swl' },
    { name: 'Asset #', prop: 'asset_Num' },
    { name: 'Category', prop: 'category' },
    { name: 'Other Details', prop: 'other_Details' },
    { name: 'Internal Location', prop: 'internal_Location' },
    { name: 'Date of Manufacturer', prop: 'date_Of_Manuf_Formatted' },
    { name: 'Decommissioned', prop: 'decommissioned_Formatted' },
    { name: 'Inspection Job Number', prop: 'job_No' },
    { name: 'Company', prop: 'company' },
    { name: 'Location', prop: 'location' },
    { name: 'Inspection Status', prop: 'state_Formatted' },
    { name: 'Service Date', prop: 'service_Inspection_Date_Formatted' },
    { name: 'Next Service Date', prop: 'service_Next_Inspection_Date_Formatted' },
    { name: 'Service Job No', prop: 'service_Job_No' },
    { name: 'Service status', prop: 'service_Safe_For_Use_Formatted' },
    { name: 'Breakdown Date', prop: 'breakdown_Inspection_Date_Formatted' },
    { name: 'Breakdown Job No', prop: 'breakdown_Job_No' },
    { name: 'Installation Date', prop: 'install_Inspection_Date_Formatted' },
    { name: 'Installation Job No', prop: 'install_Job_No' },

  ];




  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this.columns = this.columns.filter(c => {
        return c.name !== col.name;
      });
    } else {
      this.columns = [...this.columns, col];
    }
    this.toggleSelection();
  }

  isChecked(col) {
    return (
      this.columns.find(c => {
        return c.name === col.name;
      }) !== undefined
    );
  }

  toggleSelection() {
    this.toggleOpen = !this.toggleOpen;
  }

  
  constructor(public assetRegisterService: AssetRegisterService, public reportableService: ReportableService, public categoryService: CategoryService,
    public router: Router, private authService: AuthService, private companyService: CompanyService, private columnService: ColumnService,
    private locationService: LocationService, private filterService: FilterService,
    public basketService: BasketService, private storageService: StorageService) {
    this.user = localStorage.getItem("currentUser");
    this.user = JSON.parse(this.user);
    //console.log(this.user);
    this.is_client = this.user.userTypeId == 1;

    
    this.page.pageNumber = 0;
    this.page.size = 10;

    categoryService.getAll().then((data) => {
      this.categories = data;
    });

    companyService.getAll().then((data) => {
      this.companies = data;

      if (this.is_client) {
        this.filter.companyId = this.user.companyId;
        this.filter.locationId = 0;
      }

      locationService.getAll().then((data) => {
        this.locationList = data;
        this.locations = this.locationList;

        if (this.is_client || this.filter.companyId != 0) {
          this.locations = this.locationList.filter(c => {
            return c.companyId == this.filter.companyId;
          });
        }
        this.loadTable(this.page,false);
      });
    });
  }

  ngOnInit() {
    var page = JSON.parse(this.storageService.get("asset_register_page"));
    var filter = JSON.parse(this.storageService.get("asset_register_filters"));
    if (page != undefined) {
      this.page = page;
      console.log(this.page);
    }
    if (filter != undefined) {
      this.filter = filter;
      this.filter.start_Date = moment(this.filter.start_Date).toDate();
      this.filter.end_Date = moment(this.filter.end_Date).toDate();
      
      
    }
    if (page == undefined && filter == undefined) {
      console.log("no filters");
      this.setPage({ offset: 0 });
      this.init = true;
      this.resetFilters(); 
    } else {
      this.currentPageLimit = this.page.size;
      this.loadTable(this.page,false);
    }

    //this.limit = 10;
    ////this.loadTable(this.page);
    //this.init = true;
    //this.toggleOpen = false;
  }

  resetFilters() {
    
    this.filter = {
      companyId: 0,
      locationId: 0,
      date_Range: 1,
      start_Date: moment().add(-1, 'months').toDate(),
      end_Date: moment().toDate(),
      serial_Num: '',
      internal_Location: '',
      quickSearch: '',
      status: -1,
      job_No: '',
      decommissioned: false,
      category_Id:0
    };
    if (this.is_client) {
      this.filter.companyId = this.user.companyId;
    }
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.currentPageLimit = this.page.size;
  }

  onCompanyChange(value) {
    console.log(value);
    if (value == null || value == undefined) {
      value = 0;
    }

    this.filter.locationId = 0;
    if (value == 0) { value = null; }

    this.locations = this.locationList.filter(c => {
      return value == null || c.companyId == value;
    });
  }

  

  //resetFilters() {
  //  this.filter = this.filterService.parseFilters(JSON.stringify(this.default_filters));

  //  if (this.is_client) {
  //    this.filter.companyId = this.user.companyId;

  //    this.onCompanyChange(this.filter.companyId);
  //  } else {
  //    this.locations = this.locationList;
  //  }

  //  this.loadTable(this.page);
  //  this.toggleOpen = false;
  //}

  // ###################################
  // TABLE
  // ###################################
  public onLimitChange(limit: any): void {
    this.page.size = parseInt(limit, 10);
    this.loadTable(this.page,false);
  }

  setPage(pageInfo) {
    console.log(pageInfo);
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page,false);
    this.toggleOpen = false;

  //  this.rows = rows;
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page,false);
  }


  loadTable(page: Page, clicked) {
    //this should always be the case but just incase
    if (this.is_client) {
      this.filter.companyId = this.user.companyId;
    }

    if (clicked) {
      this.page.pageNumber = 0;
      this.currentPageLimit = this.page.size;
      this.setPage({ offset: 0 });
      return;
    }
    this.storageService.dataSave("asset_register_filters", JSON.stringify(this.filter));
    this.storageService.dataSave("asset_register_page", JSON.stringify(this.page));

    if (this.filter.companyId == null || this.filter.companyId == undefined) {
      this.filter.companyId = 0;
    }
    if (this.filter.locationId == null || this.filter.locationId == undefined) {
      this.filter.locationId = 0;
    }
    this.filterService.saveFilters(this.authService.currentUser.value.id, this.router.url, this.filter);
    this.assetRegisterService.getResults(this.page, this.filter.companyId, this.filter.locationId, this.filter.start_Date,
      this.filter.end_Date, this.filter.serial_Num, this.filter.internal_Location, this.filter.status, this.filter.quickSearch, this.filter.job_No,
      this.filter.decommissioned, this.filter.category_Id).then((pagedData) => {
        console.log(this.page);
        this.page = pagedData.page;
        this.rows = pagedData.data;
        console.log(pagedData);
        this.refreshbasketrow();

        if (this.init) {
          this.gotoTop();
          this.init = false;
        }
      });
    this.getcolumns();
    
  }

  getcolumns() {
    this.columnService.getColumns(this.authService.currentUser.value.userId, "assetregister").then((data) => {
      if (data.columns != null) {
        this.columns = this.columns.filter(c => {
          return data.columns.includes(c.name);
        });
        this.toggleOpen = false;
      }

    });
  }

  SaveSelection() {
    let result = this.columns.map(a => a.name);
    this.columnService.save(this.authService.currentUser.value.userId, "assetregister", result).then((data) => {
    });
  }

  downloadServiceReport(id: number) {
    this.reportableService.getServiceExam(id, false).then((data) => {
      importedSaveAs(data, 'Service Report.pdf');
    });
  }

  downloadBreakdownReport(id: number) {
    this.reportableService.getBreakdown(id).then((data) => {
      importedSaveAs(data, 'Breakdown Inspection Report.pdf');
    });
  }
  downloadInstallReport(id: number) {
    this.reportableService.getInstallationExam(id).then((data) => {
      importedSaveAs(data, 'Installation Report.pdf');
    });
  }

  downloadReport(id: number) {
    this.reportableService.getLiftingExam(id).then((data) => {
      importedSaveAs(data, 'Inspection Report.pdf');
    });
  }

  downloadAssetRegisterReport(id: String) {
    this.reportableService.getAssetRegister(id).then((data) => {
      importedSaveAs(data, 'Asset Register Report.pdf');
    });
  }
  downloadChecklistReport(id: number) {
    this.reportableService.getChecklist(id).then((data) => {
      importedSaveAs(data, 'Checklist Report.pdf');
    });
  }

  

  dateChange(ev) {
    if (ev == -1) {
      return;
    }


    let date = moment();
    if (ev == -2) {
      date.set('year', 2010);
      date.set('month', 0);
      date.set('date', 1); 
    }
    else {
      if (ev == 0) {
        date = date.subtract(1, 'weeks');
      } else {
        date = date.subtract(ev, 'months');
      }
    }
    
    

    let jsDate = date.toDate();
    //var startDate: NgbDateStruct = { year: jsDate.getFullYear(), month: jsDate.getMonth() + 1, day: jsDate.getDate() };
    this.filter.start_Date = jsDate;
  }

  generateExcel() {
    this.assetRegisterService.getExcel(this.filter.companyId, this.filter.locationId, this.filter.start_Date,
      this.filter.end_Date, this.filter.serial_Num, this.filter.internal_Location, this.filter.status, this.filter.quickSearch, this.filter.job_No,
      this.filter.decommissioned, this.filter.category_Id).then((data) => {
      importedSaveAs(data, 'Report.xlsx');
    });
  }


  



 // refreshbasketrow() {
 //   var items = this.basketService.getitems();
 //   for (let i = 0; i < (this.rows.length); i++) {
 //     let row = this.rows[i];
 //     for (let k = 0; k < (items.value.length); k++) {
 //       let item = items.value[k];
 //       if (item.id == row.inspection_Id) {
 //         this.rows[i].added = true;
 //       }
 //     }
 //   }
 // }
 // basketOnclickEvent(ev) {
 //   console.log(ev);
 //   console.log("CLICKED");
 ////   this.refreshbasketrow();
 //   for (let i = 0; i < (this.rows.length); i++) {
 //     let row = this.rows[i];
 //       if (ev.id == row.inspection_Id) {
 //         this.rows[i].added = false;
 //     }
 //   }
 // }
  gotoTop() {
    console.log("GOTO TOP");
    // window.scrollTo(-9999,-99999);
    let top = document.getElementById('top');
    console.log(top);
    if (top !== null) {
      top.scrollIntoView(true);
      //  document.getElementById("top").scrollTop -= 99999;
      top = null;
    }
  }




  //BASKET/////////////////////////////////////////////////////////////////////////////////
  addToBasket(item, type) {
    if (type == 1) {
      item.id = item.inspection_ID;
      this.basketService.addROTEtoBasket(item);
    }
    else if (type == 7) {
      item.id = item.service_ID;
      item.job_No = item.serial_Num;
      item.engineer = item.description;
      this.basketService.addClientServicetoBasket(item);
    } else if (type == 8) {
      item.id = item.breakdown_ID;
      this.basketService.addBreakdowntoBasket(item);
    } else if (type == 5) {
      item.id = item.install_ID;
      this.basketService.addInstallationtoBasket(item);
    } else if (type == 6) {
      item.id = item.inspection_ID;
      this.basketService.addChecklisttoBasket(item);
    }
      
      

  }

  removeFromBasket(item, type) {
    if (type == 1)
      this.basketService.removeROTE(item);
    else if (type == 7) {
      item.id = item.service_ID;
      this.basketService.removeClientService(item);
    } else if (type == 8) {
      item.id = item.breakdown_ID;
      this.basketService.removeBreakdown(item);
    }else if (type == 5) {
      item.id = item.install_ID;
      this.basketService.removeInstallation(item);
    }else if (type == 6) {
      item.id = item.inspection_ID;
      this.basketService.removeChecklist(item);
    }

  }

  resetItem(item) {
    item.addedrote = false;
    item.addedclientservice = false;
    item.addedbreakdown = false;
    item.addedinstallation = false;
    item.addedchecklist = false;

  }

  resetItems() {
    for (let item of this.rows) {
      this.resetItem(item);
    }
  }

  basketCompleted(data) {
    this.resetItems();
  }

  basketClearing(data) {
    this.resetItems();
  }

 


  refreshbasketrow() {
    console.log("REFRESHING");
    var items = this.basketService.getitems();
    for (let i = 0; i < (this.rows.length); i++) {
      let row = this.rows[i];
      for (let k = 0; k < (items.value.length); k++) {
        let item = items.value[k];
        if (item.report_Type == 1) { //ROTE
          if ((item.description == row.description) && (item.serial_Num == row.serial_Num)) {
            this.rows[i].addedrote = true;
          }
        } else if (item.report_Type == 2) { //SERVICE
          if ((item.description == row.description) && (item.serial_Num == row.serial_Num)) {
            this.rows[i].addedservice = true;
          }
        } else if (item.report_Type == 3) { //RAMS
          if ((item.guid == row.ramS_Id) || (item.guid == row.location_Inspection_Id)) {
            this.rows[i].addedrams = true;
          }
        } else if (item.report_Type == 4) { //ERSTR
          if ((item.description == row.engineer) && (item.serial_Num == row.job_No)) {
            this.rows[i].addedestr = true;
          }
        }else if (item.report_Type == 7) { //Client Service
          if ((item.description == row.description) && (item.serial_Num == row.serial_Num)) {
            this.rows[i].addedclientservice = true;
          }
        } else if(item.report_Type == 8) { //breakdown
          if ((item.description == row.description) && (item.serial_Num == row.serial_Num)) {
            this.rows[i].addedbreakdown = true;
          }

        } else if (item.report_Type == 5) { //Install
          if ((item.description == row.engineer) && (item.serial_Num == row.job_No)) {
            this.rows[i].addedinstallation = true;
          }
        } else if (item.report_Type == 6) { //Checklist
          if ((item.description == row.description) && (item.serial_Num == row.serial_Num)) {
            this.rows[i].addedchecklist = true;
          }
        }
      }

    }
  }


  basketOnclickEvent(ev) {
    console.log(ev);
    console.log("CLICKED");
    //   this.refreshbasketrow();
    for (let i = 0; i < (this.rows.length); i++) {
      let row = this.rows[i];
      if (ev.report_Type == 1) { //ROTE
        if ((ev.description == row.description) && (ev.serial_Num == row.serial_Num)) {
          this.rows[i].addedrote = false;
        }
      } else if (ev.report_Type == 2) { //SERVICE
        if ((ev.description == row.description) && (ev.serial_Num == row.serial_Num)) {
          this.rows[i].addedservice = false;
        }
      } else if (ev.report_Type == 3) { //RAMS
        if ((ev.guid == row.ramS_Id) || (ev.guid == row.location_Inspection_Id)) {
          this.rows[i].addedrams = false;
        }
      } else if (ev.report_Type == 4) { //ERSTR
        if ((ev.description == row.engineer) && (ev.serial_Num == row.job_No)) {
          this.rows[i].addedestr = false;
        }
      }else if (ev.report_Type == 7) { //Client Service
        if ((ev.description == row.description) && (ev.serial_Num == row.serial_Num)) {
          this.rows[i].addedclientservice = false;
        }
      }else if (ev.report_Type == 8) { //Client Service
        if ((ev.description == row.description) && (ev.serial_Num == row.serial_Num)) {
          this.rows[i].addedbreakdown = false;
        }
      } else if (ev.report_Type == 5) { //Install
        if ((ev.description == row.engineer) && (ev.serial_Num == row.job_No)) {
          this.rows[i].addedinstallation = false;
        }
      } else if (ev.report_Type == 6) { //Checklist
        if ((ev.description == row.description) && (ev.serial_Num == row.serial_Num)) {
          this.rows[i].addedchecklist = false;
        }
      }
    }
  }


}

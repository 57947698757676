import { Component, OnInit } from '@angular/core';

import { Company } from '../../../models/company';
import { Location } from '../../../models/location';

import { CompanyService } from '../../../services/company.service';
import { LocationService } from '../../../services/location.service';
import { MassUploadService } from '../../../services/massupload.service';
import { JobService } from '../../../services/job.service';

import { Page } from '../../../models/paging/page';
import { Location_Inspection } from '../../../models/location_inspection';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-massupload',
  templateUrl: './massupload.component.html',
  styleUrls: ['./massupload.component.css']
})
export class MassUploadComponent implements OnInit {
  base64: string;
  init: boolean;
  user;
  rows: any[];
  hasErrors = false;

  constructor(public confirmationService: ConfirmationService, public router: Router, public massuploadService: MassUploadService, private snotifyService: SnotifyService) {
    this.user = localStorage.getItem("currentUser");
    this.user = JSON.parse(this.user);
}

  ngOnInit() {

      this.gotoTop();
  }
  columns = [
    { name: 'Row', prop: 'row' },
    { name: 'Company', prop: 'company' },
    { name: 'Location', prop: 'location' },
    { name: 'Description', prop: 'description' },
    { name: 'Serial Number', prop: 'serial_Number' },
    { name: 'Asset Number', prop: 'asset_Number' },
    { name: 'Manufacturer', prop: 'manufacturer' },
    { name: 'Date of Manufacture', prop: 'date_of_Manufacture' },
    { name: 'SWL', prop: 'swl' },
    { name: 'Interval', prop: 'interval' },
    { name: 'CE', prop: 'ce' },
    { name: 'Owner', prop: 'owner' },
    { name: 'Location of ID Markings', prop: 'location_of_ID_Markings' },
    { name: 'Date Of Last Inspection', prop: 'date_Of_last_inspection' },
    { name: 'Last Inspection Status', prop: 'last_Inspection_Status' },
    { name: 'Error', prop: 'error' },
  ];


  changeListener(e): void {
    var rawBase64 = null;
    var base64 = null;

    // If a file was selected
    if (e.target.files.length > 0) {

      let file = e.target.files[0];
      var reader = new FileReader();

      reader.readAsDataURL(file);

      // Split the initial data type string from the base64 data
      reader.onload = (e) => {
        rawBase64 = reader.result;
        base64 = rawBase64.split(",", 2);
        this.base64 = base64[1];
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  }

  upload(): void {
    console.log("COMPONENT");
    console.log(this.base64);
    if (this.base64 != null) {
      this.massuploadService.uploadEquipmentFile(this.base64, this.user.userId).then((data) => {
        console.log(data);
        this.base64 = null;
        this.rows = data;
        this.hasErrors = true;
        if (this.rows != [] && this.rows != null) {
          this.hasErrors = true;
          this.snotifyService.error('Error adding asset please see problems in the table');
        } else {
          this.hasErrors = false;
          this.snotifyService.success('Successfully added asset');
        }
        
        (<HTMLInputElement>document.getElementById('filePicker')).value = '';
      }).catch((err) => {
        this.base64 = null;
        (<HTMLInputElement>document.getElementById('filePicker')).value = '';

        this.snotifyService.error(err.error);
      });
    }
  }
  gotoTop() {
    console.log("GOTO TOP");
    // window.scrollTo(-9999,-99999);
    let top = document.getElementById('top');
    console.log(top);
    if (top !== null) {
      top.scrollIntoView(true);
      //  document.getElementById("top").scrollTop -= 99999;
      top = null;
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { PagedData } from '../models/paging/paged-data';
import { Page } from '../models/paging/page';
import { ServerData } from '../models/paging/server-data';

import { AuthService } from '../services/auth.service';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { Inspection } from '../models/inspection';
import { ApiResponse } from '../models/api-response';
import { SnotifyService } from 'ng-snotify';
import { Location_Inspection } from '../models/location_inspection';

@Injectable()
export class InspectionService {
  constructor(private http: HttpClient, private authService: AuthService, private snotifyService: SnotifyService) { }

  public getResults(page: Page, company_Id: number, location_Id: number, tabletUser_Id: number, category_Id: number, start_Date: string,
    end_Date: string, serial_Num: string, internal_Location: string, job_No: string, status: number, quickSearch: string, decommissioned: boolean): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => { 
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'Inspected_At'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'desc'; }

      let params = '&company_Id=' + company_Id;
      params += '&location_Id=' + location_Id;
      params += '&tabletUser_Id=' + tabletUser_Id;
      params += '&category_Id=' + category_Id;
      params += '&start_Date=' + moment(start_Date).format('YYYY-MM-DD');
      params += '&end_Date=' + moment(end_Date).add(1, 'days').format('YYYY-MM-DD')
      params += '&serial_Num=' + serial_Num;
      params += '&internal_Location=' + internal_Location;
      params += '&job_No=' + job_No;
      params += '&status=' + status;
      params += '&quickSearch=' + quickSearch;
      params += '&decommissioned=' + decommissioned;
      //var is_Client = this.authService.currentUser.value.userType == 0;
      //params += '&is_Client=' + is_Client;
      console.log("in service");
      console.log(params);
      this.http.get<ServerData>(environment.apiUrl + 'inspection/gettable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params, { headers: headers }).subscribe(data => {
        console.log("in service");
        console.log(data);
        page.totalElements = data.total;
        page.passed = data.passed;
        page.failed = data.failed;
        page.missing = data.missing;
        page.full_Count = data.full_Count;
        page.totalPages = page.totalElements / page.size;


        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }

  public getInspectionHisoryResults(page: Page, inspection_Id: number): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();

    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => { 
      let params = '&inspection_Id=' + inspection_Id;
      //var is_Client = this.authService.currentUser.value.userType == 0;
      //params += '&is_Client=' + is_Client;
      console.log("in service");
      console.log(params);
      this.http.get<ServerData>(environment.apiUrl + 'inspection/gethistoricaltable?size=' + page.size + '&pageNumber=' + page.pageNumber  + params, { headers: headers }).subscribe(data => {
        console.log("in service");
        console.log(data);
        page.totalElements = data.total;
        page.passed = data.passed;
        page.failed = data.failed;
        page.missing = data.missing;
        page.full_Count = data.full_Count;
        page.totalPages = page.totalElements / page.size;


        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }

  public get(id: Number): Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };
    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + 'inspection/get?id=' + id).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getAll(page: Page, company_Id: number, location_Id: number, tabletUser_Id: string, category_Id: number, start_Date: string,
    end_Date: string, serial_Num: string, internal_Location: string, job_No: string, status: number, quickSearch: string, decommissioned: boolean): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {


      let params = '&company_Id=' + company_Id;
      params += '&location_Id=' + location_Id;
      params += '&tabletUser_Id=' + tabletUser_Id;
      params += '&category_Id=' + category_Id;
      params += '&start_Date=' + moment(start_Date).format('YYYY-MM-DD');
      params += '&end_Date=' + moment(end_Date).add(1, 'days').format('YYYY-MM-DD')
      params += '&serial_Num=' + serial_Num;
      params += '&internal_Location=' + internal_Location;
      params += '&job_No=' + job_No;
      params += '&status=' + status;
      params += '&quickSearch=' + quickSearch;
      params += '&decommissioned=' + decommissioned;
      //var is_Client = this.authService.currentUser.value.userType == 0;
      //params += '&is_Client=' + is_Client;
      console.log("in service");
      console.log(params);
      this.http.get<ServerData>(environment.apiUrl + 'inspection/getAll?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params, { headers: headers }).subscribe(data => {
        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }
  public getJobs(id: Number): Promise<Location_Inspection[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {

      this.http.get<Location_Inspection[]>(environment.apiUrl + 'inspection/getJobs?id=' + id, { headers: headers }).subscribe(data => {
        resolve(data);

      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  public getImages(inspectionId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>('api/inspection/getimages?inspectionId=' + inspectionId.toString()).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/zip');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  converBase64toBlob(content, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = window.atob(content); //method which converts base64 to binary
    let byteArrays = [
    ];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }

  public getApprovalDetails(page: Page, locationinspection_Id:string): Promise<any> {
    const pagedData = new PagedData<any>();

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'Inspected_At'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'desc'; }

      let params = '&locationinspection_Id=' + locationinspection_Id;

      this.http.get<any>('/api/inspection/getUnapproved?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params).subscribe(data => {
        page.totalElements = data.total;
        page.totalPages = page.totalElements / page.size;

        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }
  public getExcel(company_Id: number, location_Id: number, tabletUser_Id: string, category_Id: number, start_Date: string,
    end_Date: string, serial_Num: string, internal_Location: string, job_No: string, status: number, quickSearch: string, decommissioned: boolean): Promise<any> {
  
    return new Promise((resolve, reject) => {
      let params = '?company_Id=' + company_Id;
      params += '&location_Id=' + location_Id;
      params += '&tabletUser_Id=' + tabletUser_Id;
      params += '&category_Id=' + category_Id;
      params += '&start_Date=' + moment(start_Date).format('YYYY-MM-DD');
      params += '&end_Date=' + moment(end_Date).add(1, 'days').format('YYYY-MM-DD')
      params += '&serial_Num=' + serial_Num;
      params += '&internal_Location=' + internal_Location;
      params += '&job_No=' + job_No;
      params += '&status=' + status;
      params += '&quickSearch=' + quickSearch;
      params += '&decommissioned=' + decommissioned;

      this.http.get<any>(environment.apiUrl + 'inspection/getexcel' + params).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/vnd.ms-excel');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public save(inspection: any): Promise<ApiResponse> {

    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      console.log("inspection service");
      console.log(inspection);

      this.http.post<ApiResponse>(environment.apiUrl + 'inspection/postnewInspection', inspection, { headers: headers }).subscribe(data => {
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public update(inspection: any): Promise<ApiResponse> {

    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      console.log("inspection service");
      console.log(inspection);

      this.http.post<ApiResponse>(environment.apiUrl + 'inspection/post', inspection, { headers: headers }).subscribe(data => {
        resolve(data);
        this.snotifyService.success('Successfully updated inspection');
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public saveQuickPass(equipment_Ids: any, job_Id: any, pda_User_Id:any, inspected_At: any, purpose_ID: any, colour_ID:any): Promise<ApiResponse> {

    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      console.log("inspection service");
      var dto = {
        equipment_Ids: equipment_Ids,
        location_Inspection_ID: job_Id,
        PDA_User_ID: pda_User_Id,
        inspected_At: inspected_At,
        purpose_ID: purpose_ID,
        colour_ID: colour_ID,
      }
      console.log(dto);
      this.http.post<ApiResponse>(environment.apiUrl + 'inspection/postquickpass', dto, { headers: headers }).subscribe(data => {
        resolve(data);
        this.snotifyService.success('Successfully passed inspections');
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  public saveQuickMissing(equipment_Ids: any, job_Id: any,
    pda_User_Id: any, inspected_At: any, purpose_ID: any,
    colour_ID: any): Promise<ApiResponse> {

    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      console.log("inspection service");
      var dto = {
        equipment_Ids: equipment_Ids,
        location_Inspection_ID: job_Id,
        PDA_User_ID: pda_User_Id,
        inspected_At: inspected_At,
        purpose_ID: purpose_ID,
        colour_ID: colour_ID,
        
      }
      console.log(dto);
      this.http.post<ApiResponse>(environment.apiUrl + 'inspection/postquickmissing', dto, { headers: headers }).subscribe(data => {
        resolve(data);
        this.snotifyService.success('Successfully set asset as missing');
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public saveRemainingMissing(job_Id: any,
    pda_User_Id: any, inspected_At: any, purpose_ID: any,
    colour_ID: any): Promise<ApiResponse> {

    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      console.log("inspection service");
      var dto = {
        equipment_Ids: null,
        location_Inspection_ID: job_Id,
        PDA_User_ID: pda_User_Id,
        inspected_At: inspected_At,
        purpose_ID: purpose_ID,
        colour_ID: colour_ID,
        
      }
      console.log(dto);
      this.http.post<ApiResponse>(environment.apiUrl + 'inspection/postremainingmissing', dto, { headers: headers }).subscribe(data => {
        resolve(data);
        this.snotifyService.success('Successfully set remaining assets as missing');
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  public saveQuickScrap(equipment_Ids: any, job_Id: any,
    pda_User_Id: any, inspected_At: any, purpose_ID: any,
    colour_ID: any, scrap: any): Promise<ApiResponse> {

    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      console.log("inspection service");
      var dto = {
        equipment_Ids: equipment_Ids,
        location_Inspection_ID: job_Id,
        PDA_User_ID: pda_User_Id,
        inspected_At: inspected_At,
        purpose_ID: purpose_ID,
        colour_ID: colour_ID,
        scrap: scrap,
      }
      console.log(dto);
      this.http.post<ApiResponse>(environment.apiUrl + 'inspection/postquickscrap', dto, { headers: headers }).subscribe(data => {
        resolve(data);
        this.snotifyService.success('Successfully scrapped asset and failed inspection');
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public saveQuickDecommission(equipment_Ids: any, job_Id: any,
    pda_User_Id: any, inspected_At: any, purpose_ID: any,
    colour_ID: any): Promise<ApiResponse> {

    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      console.log("inspection service");
      var dto = {
        equipment_Ids: equipment_Ids,
        location_Inspection_ID: job_Id,
        PDA_User_ID: pda_User_Id,
        inspected_At: inspected_At,
        purpose_ID: purpose_ID,
        colour_ID: colour_ID,
      }
      console.log(dto);
      this.http.post<ApiResponse>(environment.apiUrl + 'inspection/postquickdecomission', dto, { headers: headers }).subscribe(data => {
        resolve(data);
        this.snotifyService.success('Successfully decomissioned asset');
      }, error => {
        console.log(error);
        reject();
      });
    });
  }


  public getAllInspections(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>('/api/inspection/getallInspections').subscribe(data => {
        resolve(data);
      }, error => {
        reject();
      });
    });
  }

  public getAllInspectionsEngineers(): Promise<any> {

    return new Promise((resolve, reject) => {
      this.http.get<any>('/api/inspection/getAllInspectionsEngineers').subscribe(data => {
        
        resolve(data);
        
      }, error => {
        reject();
      });
    });
  }



}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { PagedData } from '../models/paging/paged-data';
import { Page } from '../models/paging/page';
import { ServerData } from '../models/paging/server-data';

import { AuthService } from '../services/auth.service';
import * as moment from 'moment';
import { environment } from '../../environments/environment';

@Injectable()
export class ServiceInspectionService {
  constructor(private http: HttpClient, private authService: AuthService) { }

  public getResults(page: Page, company_Id: number, location_Id: number, tabletUser_Id: string, category_Id: number,
    start_Date: string, end_Date: string, serial_Num: string, fault: number, status: number, quickSearch: string, job_No: string): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'PDA_StartTime'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'desc'; }

      let params = '&company_Id=' + company_Id;
      params += '&location_Id=' + location_Id;
      params += '&tabletUser_Id=' + tabletUser_Id;
      params += '&category_Id=' + category_Id;
      params += '&start_Date=' + moment(start_Date).format('YYYY-MM-DD');
      params += '&end_Date=' + moment(end_Date).add(1, 'days').format('YYYY-MM-DD')
      params += '&serial_Num=' + serial_Num;
      params += '&fault=' + fault;
      params += '&status=' + status;
      params += '&quickSearch=' + quickSearch;
      params += '&job_No=' + job_No;
      //var is_Client = this.authService.currentUser.value.userType == 0;
      //params += '&is_Client=' + is_Client;
      console.log("in service");
      console.log(params);
      this.http.get<ServerData>(environment.apiUrl + 'serviceinspection/gettable?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params, { headers: headers }).subscribe(data => {
        console.log("in service");
        console.log(data);
        page.totalElements = data.total;
        page.threemonthCount = data.threemonthCount;
        page.sixmonthCount = data.sixmonthCount;
        page.twelvemonthCount = data.twelvemonthCount;
        page.full_Count = data.full_Count;
        page.totalPages = page.totalElements / page.size;

        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }

  public getall(page: Page, company_Id: number, location_Id: number, tabletUser_Id: string, category_Id: number,
    start_Date: string, end_Date: string, serial_Num: string, fault: number, status: number, quickSearch: string, job_No: string): Promise<PagedData<any>> {
    const pagedData = new PagedData<any>();
    const headers = {
      'Content-Type': 'application/json',
    };

    return new Promise((resolve, reject) => {
      if (page.sortBy == undefined || page.sortBy == '') { page.sortBy = 'PDA_StartTime'; }
      if (page.sortOrder == undefined || page.sortOrder == '') { page.sortOrder = 'desc'; }

      let params = '&company_Id=' + company_Id;
      params += '&location_Id=' + location_Id;
      params += '&tabletUser_Id=' + tabletUser_Id;
      params += '&category_Id=' + category_Id;
      params += '&start_Date=' + moment(start_Date).format('YYYY-MM-DD');
      params += '&end_Date=' + moment(end_Date).add(1, 'days').format('YYYY-MM-DD')
      params += '&serial_Num=' + serial_Num;
      params += '&fault=' + fault;
      params += '&status=' + status;
      params += '&quickSearch=' + quickSearch;
      params += '&job_No=' + job_No;
      //var is_Client = this.authService.currentUser.value.userType == 0;
      //params += '&is_Client=' + is_Client;
      console.log("in service");
      console.log(params);
      this.http.get<ServerData>(environment.apiUrl + 'serviceinspection/getall?size=' + page.size + '&pageNumber=' + page.pageNumber + '&sortBy=' + page.sortBy + '&sortOrder=' + page.sortOrder + params, { headers: headers }).subscribe(data => {
        console.log("in service");
        console.log(data);
        page.totalElements = data.total;
        page.threemonthCount = data.threemonthCount;
        page.sixmonthCount = data.sixmonthCount;
        page.twelvemonthCount = data.twelvemonthCount;
        page.full_Count = data.full_Count;
        page.totalPages = page.totalElements / page.size;

        for (let i = 0; i < data.data.length; i++) {
          const jsonObj = data.data[i];
          pagedData.data.push(jsonObj);
        }

        pagedData.page = page;
        resolve(pagedData);

      }, error => {
        reject();
      });
    });
  }

  public getExcel(company_Id: number, location_Id: number, tabletUser_Id: string, category_Id: number,
    start_Date: string, end_Date: string, serial_Num: string, fault: number, status: number, quickSearch: string, job_No: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let params = '?company_Id=' + company_Id;
      params += '&location_Id=' + location_Id;
      params += '&tabletUser_Id=' + tabletUser_Id;
      params += '&category_Id=' + category_Id;
      params += '&start_Date=' + moment(start_Date).format('YYYY-MM-DD');
      params += '&end_Date=' + moment(end_Date).add(1, 'days').format('YYYY-MM-DD')
      params += '&serial_Num=' + serial_Num;
      params += '&fault=' + fault;
      params += '&status=' + status;
      params += '&quickSearch=' + quickSearch;
      params += '&job_No=' + job_No;

      this.http.get<any>(environment.apiUrl + 'serviceinspection/getexcel' + params).subscribe(data => {
        let blob = this.converBase64toBlob(data, 'application/vnd.ms-excel');
        resolve(blob);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
  converBase64toBlob(content, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;
    let byteCharacters = window.atob(content); //method which converts base64 to binary
    let byteArrays = [
    ];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }



  public getAllServiceInspections(): Promise<any> {
    return new Promise((resolve, reject) => {

      this.http.get<any>('/api/serviceinspection/GetAllServiceInspections').subscribe(data => {
        resolve(data);
      }, error => {
        reject();
      });
    });
  }



  public getInAndOutDateInspections(): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log("in service");
      this.http.get<any>('/api/serviceinspection/GetInAndOutDateServiceInspections').subscribe(data => {
        console.log(data);
        resolve(data);
        
      }, error => {
        reject();
      });
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { Location } from '../../../models/location';
import { Company } from '../../../models/company';
import { Category } from '../../../models/category';
import { Page } from '../../../models/paging/page';
import { Equipment } from '../../../models/equipment';
import { CompanyService } from '../../../services/company.service';
import { LocationService } from '../../../services/location.service';
import { CategoryService } from '../../../services/category.service';
import { EquipmentService } from '../../../services/equipment.service';
import { Router } from '@angular/router';
import { saveAs as importedSaveAs } from 'file-saver';
import { ColumnService } from '../../../services/column.service';
import { AuthService } from '../../../services/auth.service';
import { AppComponent } from '../../../app.component';
import { StorageService } from '../../../services/storage.service';
import { ReportableService } from '../../../services/reportable.service';

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.css']
})
export class EquipmentComponent implements OnInit {

  filter: any = {
    company_Id: 0,
    location_Id: 0,
    category_Id: 0,
    serial_Num: '',
    internal_Location: '',
    asset_Num: '',
    quickSearch: '',
    decommissioned: false,
    archived: false
  };

  locationList: Location[];
  user;
  companies: Company[];
  locations: Location[];
  init: boolean;
  categories: Category[];
  toggleOpen = false;

  page = new Page();
  rows = Array<Equipment>();

  public readonly pageLimitOptions = [
    { value: 5 },
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ];


  public currentPageLimit: number = 10;

  columns = [
    { name: 'Description', prop: 'description' },
    { name: 'Serial #', prop: 'serial_Num' },
    { name: 'Asset #', prop: 'asset_Num' },
    { name: 'SWL', prop: 'swl' },
    { name: 'Category', prop: 'category' },
    { name: 'Manufacturer', prop: 'manufacturer' },
    { name: 'Date of Manufacturer', prop: 'date_Of_Manuf_Formatted' },
    { name: 'Decommissioned', prop: 'decommissioned_Formatted' },
    { name: 'Created', prop: 'created_Formatted' },
    { name: 'Owner', prop: 'owner_Formatted' },
    { name: 'Original CE', prop: 'original_Cert' },
    { name: 'Location', prop: 'location' },
    { name: 'Company', prop: 'company' },
    { name: 'Other Details', prop: 'other_Details' },
    { name: 'Internal Location', prop: 'internal_Location' },
    { name: 'Status', prop: 'state' },
    { name: 'Next Inspection Date', prop: 'next_Insp_Date_Formatted' },
    { name: 'Inspection Date', prop: 'inspected_At_Formatted' },
  ];

  allColumns = [
    { name: 'Description', prop: 'description' },
    { name: 'Serial #', prop: 'serial_Num' },
    { name: 'Asset #', prop: 'asset_Num' },
    { name: 'SWL', prop: 'swl' },
    { name: 'Category', prop: 'category' },
    { name: 'Manufacturer', prop: 'manufacturer' },
    { name: 'Date of Manufacturer', prop: 'date_Of_Manuf_Formatted' },
    { name: 'Decommissioned', prop: 'decommissioned_Formatted' },
    { name: 'Created', prop: 'created_Formatted' },
    { name: 'Owner', prop: 'owner_Formatted' },
    { name: 'Original CE', prop: 'original_Cert' },
    { name: 'Location', prop: 'location' },
    { name: 'Company', prop: 'company' },
    { name: 'Other Details', prop: 'other_Details' },
    { name: 'Internal Location', prop: 'internal_Location' },
    { name: 'Status', prop: 'state' },
    { name: 'Next Inspection Date', prop: 'next_Insp_Date_Formatted' },
    { name: 'Inspection Date', prop: 'inspected_At_Formatted' },
  ];




  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this.columns = this.columns.filter(c => {
        return c.name !== col.name;
      });
    } else {
      this.columns = [...this.columns, col];
    }
    this.toggleSelection();
  }

  isChecked(col) {
    return (
      this.columns.find(c => {
        return c.name === col.name;
      }) !== undefined
    );
  }

  toggleSelection() {
    this.toggleOpen = !this.toggleOpen;
  }

  constructor(public companyService: CompanyService, public locationService: LocationService, public categoryService: CategoryService,
    public equipmentService: EquipmentService, public router: Router, private authService: AuthService,
    private columnService: ColumnService, public reportableService: ReportableService, private storageService: StorageService) {

    this.page.pageNumber = 0;
    this.page.size = 10;
    this.user = localStorage.getItem("currentUser");
    this.user = JSON.parse(this.user);
    

    companyService.getAll().then((data) => {
      this.companies = data;
    });

    locationService.getAll().then((data) => {
      this.locationList = data;
      this.locations = this.locationList;
      if (this.filter.company_Id != 0) {
        this.locations = this.locationList.filter(c => {
          return c.companyId == this.filter.company_Id;
        });
      }
    });

    categoryService.getAll().then((data) => {
      this.categories = data;
    });
  }

  onCompanyChange(value) {
    this.filter.location_Id = 0;
    if (value == 0) { value = null; }

    this.locations = this.locationList.filter(c => {
      return value == null || c.companyId == value;
    });
  }

  ngOnInit() {
    var page = JSON.parse(this.storageService.get("equipment_page"));
    var filter = JSON.parse(this.storageService.get("equipment_filters"));
    if (page != undefined) {
      this.page = page;
      console.log(this.page);
    }
    if (filter != undefined) {
      this.filter = filter;
    }
    if (page == undefined && filter == undefined) {
      this.setPage({ offset: 0 });
      this.init = true;
    } else {
      this.currentPageLimit = this.page.size;
      this.loadTable(this.page, false);
    }


    
  }
  resetFilters() {
    this.filter = {
      company_Id: 0,
      location_Id: 0,
      category_Id: 0,
      serial_Num: '',
      internal_Location: '',
      asset_Num: '',
      quickSearch: '',
      decommissioned: false,
      archived: false
    };
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.currentPageLimit = this.page.size;
  }

  edit(row) {
    

    this.router.navigate(['administration/equipment/manage'], { queryParams: { id: row.equipment_ID } });
  }

  upload(row) {
    this.router.navigate(['administration/equipment/files'], { queryParams: { id: row.equipment_ID } });
  }


  // ###################################
  // TABLE
  // ###################################
  public onLimitChange(limit: any): void {
    this.page.size = parseInt(limit, 10);
    this.loadTable(this.page, false);
  }
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable(this.page, false);
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable(this.page,false);
  }

  loadTable(page: Page,clicked) {
    if (clicked) {
      this.page.pageNumber = 0;
      this.currentPageLimit = this.page.size;
      this.setPage({ offset: 0 });
      return;
    }
    if (this.user.userTypeId != 3) { this.filter.archived =  false }
    this.storageService.dataSave("equipment_filters", JSON.stringify(this.filter));
    this.storageService.dataSave("equipment_page", JSON.stringify(this.page));

    if (this.filter.company_Id == null || this.filter.company_Id == undefined) {
      this.filter.company_Id = 0;
    }
    if (this.filter.location_Id == null || this.filter.location_Id == undefined) {
      this.filter.location_Id = 0;
    }
    if (this.filter.category_Id == null || this.filter.category_Id == undefined) {
      this.filter.category_Id = 0;
    }
    this.equipmentService.getResults(this.page, this.filter.company_Id, this.filter.location_Id,
      this.filter.category_Id, this.filter.serial_Num, this.filter.internal_Location, this.filter.asset_Num, this.filter.quickSearch, this.filter.decommissioned, this.filter.archived).then((pagedData) => {
      this.page = pagedData.page;
        this.rows = pagedData.data;
        if (this.init) {
          this.gotoTop();
          this.init = false;
        }
      });
    this.getcolumns();
  }

  getcolumns() {
    this.columnService.getColumns(this.authService.currentUser.value.userId, "equipment").then((data) => {
      if (data.columns != null) {
        this.columns = this.columns.filter(c => {
          return data.columns.includes(c.name);
        });
        this.toggleOpen = false;
      }

    });
  }
  SaveSelection() {
    let result = this.columns.map(a => a.name);
    this.columnService.save(this.authService.currentUser.value.userId, "equipment", result).then((data) => {
    });
  }
  downloadReport(id: number) {
    this.reportableService.getLiftingExam(id).then((data) => {
      importedSaveAs(data, 'Inspection Report.pdf');
    });
  }

  downloadchecklistReport(id: number) {
    this.reportableService.getChecklist(id).then((data) => {
      importedSaveAs(data, 'Checklist Report.pdf');
    });
  }
  generateExcel() {
    this.equipmentService.getExcel(this.filter.company_Id, this.filter.location_Id,
      this.filter.category_Id, this.filter.serial_Num, this.filter.internal_Location, this.filter.asset_Num, this.filter.quickSearch, this.filter.decommissioned, this.filter.archived).then((data) => {
      importedSaveAs(data, 'Report.xlsx');
    });
  }
  gotoTop() {
    console.log("GOTO TOP");
    // window.scrollTo(-9999,-99999);
    let top = document.getElementById('top');
    console.log(top);
    if (top !== null) {
      top.scrollIntoView(true);
      //  document.getElementById("top").scrollTop -= 99999;
      top = null;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Manufacturer } from '../../../../models/manufacturer';
import { ManufacturerService } from '../../../../services/manufacturer.service';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-manufacturer-manage',
  templateUrl: './manufacturer-manage.component.html',
  styleUrls: ['./manufacturer-manage.component.css']
})
export class ManufacturerManageComponent implements OnInit {
  manufacturer: Manufacturer;

  constructor(public manufacturerService: ManufacturerService, public router: Router, public snotifyService: SnotifyService, private route: ActivatedRoute) {
    this.manufacturer = new Manufacturer();
  }

  ngOnInit() {
    // Load our id if one exists
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id != undefined) {
        this.manufacturerService.get(id).then((data) => {
          this.manufacturer = data;
          this.manufacturer.is_edit = true;
        });
      }
    });
  }
  valid() {
    var valid = true;
    if (this.manufacturer.manuf == undefined || this.manufacturer.manuf == "") {
      valid = false;
      this.snotifyService.error("Please enter manufacturer name.");
    }
    return valid;
  }

  submit() {
    if (!this.valid()) {
      return;
    }
    this.manufacturerService.save(this.manufacturer).then((data) => {
      if (data.success) {
        if (!this.manufacturer.is_edit) {
          this.snotifyService.success('Successfully added manufacturer');
        } else {
          this.snotifyService.success('Successfully updated manufacturer');
        }
        this.router.navigate(['administration/manufacturers']);
      } else {
        this.snotifyService.error(data.message);
      }
      
    });
  }
}

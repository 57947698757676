import { Component, OnInit } from '@angular/core';
import { Page } from '../../../../models/paging/page';
import { Router, ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { ConfirmationService } from 'primeng/api';
import { EquipmentService } from '../../../../services/equipment.service';
import { ReportableService } from '../../../../services/reportable.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { Company } from '../../../../models/company';
import * as moment from 'moment';
import { Tablet } from '../../../../models/tablet';
import { AuthService } from '../../../../services/auth.service';
import { BasketService } from '../../../../services/basket.service';
import { ColumnService } from '../../../../services/column.service';
import { CompanyService } from '../../../../services/company.service';
import { InspectionService } from '../../../../services/inspection.service';
import { LocationService } from '../../../../services/location.service';
import { StorageService } from '../../../../services/storage.service';
import { TabletService } from '../../../../services/tablet.service';

@Component({
  selector: 'app-inspection-view-history',
  templateUrl: './inspection-view-history.component.html',
  styleUrls: ['./inspection-view-history.component.css']
})

export class InspectionViewHistoryComponent implements OnInit {

  companies: Company[];
  locations: Location[];
  locationList: Location[];
  users: Tablet[];

  filter: any = {
    inspection_Id: 0
  };
  viewtype: 0;
  page = new Page();
  rows = Array<any>();
  init: boolean;
  is_client = false;
  client;
  passedCount: any;
  failedCount: any;
  missingCount: any;
  totalCount: any;
  full_Count: any;
  toggleOpen = false;
  user;

  public readonly pageLimitOptions = [
    { value: 5 },
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 100 },
  ];

  public currentPageLimit: number = 10;

  columns = [
    { name: 'Ammended By', prop: 'ammended_By' },
    { name: 'Revision No', prop: 'revision_No' },
    { name: 'Description', prop: 'description' },
    { name: 'Serial Num', prop: 'serial_Num' },
    { name: 'Amended Date', prop: 'amended_Date_Formatted' },
    { name: 'Inspection Date', prop: 'inspected_At_Formatted' },
    { name: 'Next Inspection Date', prop: 'next_Inspection_Date_Formatted' },
    { name: 'Job Number', prop: 'job_No' },
    { name: 'Status', prop: 'state_Formatted' },
    { name: 'Engineers', prop: 'users' },
    { name: 'Location', prop: 'location' },
    { name: 'Company', prop: 'company' },
    { name: 'Asset Number', prop: 'asset_Num' },
    { name: 'Category', prop: 'category' },
    { name: 'Comments', prop: 'defects' },
    { name: 'Other Details', prop: 'other_Details' },
    { name: 'Date of Manufacturer', prop: 'date_Of_Manuf_Formatted' },
    { name: 'Colour', prop: 'colour' },
    { name: 'Internal Location', prop: 'internal_Location' },
    { name: 'SWL', prop: 'swl' },
  ];

  allColumns = [
    { name: 'Ammended By', prop: 'ammended_By' },
    { name: 'Revision No', prop: 'revision_No' },
    { name: 'Description', prop: 'description' },
    { name: 'Serial Num', prop: 'serial_Num' },
    { name: 'Amended Date', prop: 'amended_Date_Formatted' },
    { name: 'Inspection Date', prop: 'inspected_At_Formatted' },
    { name: 'Next Inspection Date', prop: 'next_Inspection_Date_Formatted' },
    { name: 'Job Number', prop: 'job_No' },
    { name: 'Status', prop: 'state_Formatted' },
    { name: 'Engineers', prop: 'users' },
    { name: 'Location', prop: 'location' },
    { name: 'Company', prop: 'company' },
    { name: 'Asset Number', prop: 'asset_Num' },
    { name: 'Category', prop: 'category' },
    { name: 'Comments', prop: 'defects' },
    { name: 'Other Details', prop: 'other_Details' },
    { name: 'Date of Manufacturer', prop: 'date_Of_Manuf_Formatted' },
    { name: 'Colour', prop: 'colour' },
    { name: 'Internal Location', prop: 'internal_Location' },
    { name: 'SWL', prop: 'swl' },
  ];




  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this.columns = this.columns.filter(c => {
        return c.name !== col.name;
      });
    } else {
      this.columns = [...this.columns, col];
    }
    this.toggleSelection();
  }

  isChecked(col) {
    return (
      this.columns.find(c => {
        return c.name === col.name;
      }) !== undefined
    );
  }

  toggleSelection() {
    this.toggleOpen = !this.toggleOpen;
  }

  constructor(private authService: AuthService, companyService: CompanyService, locationService: LocationService,
    tabletService: TabletService, public inspectionService: InspectionService, private columnService: ColumnService,
    public reportableService: ReportableService, public basketService: BasketService,
    public router: Router, private storageService: StorageService, private route: ActivatedRoute) {
    this.user = localStorage.getItem("currentUser");
    this.user = JSON.parse(this.user);
    //console.log(this.user);
    //this.is_client = this.user.userTypeId == 1;

    this.page.pageNumber = 0;
    this.page.size = 10;
    //this.page.sortOrder = 'desc';
    //this.page.sortBy = "Inspected_At";


   
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      
      const inspection_Id = params['id'];
      //const job_Id = params['jobId'];
      this.filter.inspection_Id = inspection_Id;
      //this.filter.job_Id = job_Id;
      this.loadTable();
    });
    
  }

  // ###################################
  // TABLE
  // ###################################
  public onLimitChange(limit: any): void {
    this.page.size = parseInt(limit, 10);
    this.loadTable();
  }
  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.loadTable();
  }

  onSort(event) {
    if (event.column.prop == '') {
      return false;
    }

    this.page.sortOrder = event.newValue;
    this.page.sortBy = event.column.prop;
    this.loadTable();
  }

  loadTable() {

    this.inspectionService.getInspectionHisoryResults(this.page, this.filter.inspection_Id).then((pagedData) => {
      console.log(pagedData);
        this.page = pagedData.page;
        this.rows = pagedData.data;
        this.passedCount = pagedData.page.passed;
        this.failedCount = pagedData.page.failed;
        this.missingCount = pagedData.page.missing;
        this.full_Count = pagedData.page.full_Count;
        this.totalCount = pagedData.page.totalElements;
        console.log(this.rows);
        this.refreshbasketrow();
        if (this.init) {
          this.gotoTop();
          this.init = false;
        }
      });
    this.getcolumns();
  }

  getcolumns() {
    this.columnService.getColumns(this.authService.currentUser.value.userId, "inspection_history").then((data) => {
      if (data.columns != null) {
        this.columns = this.columns.filter(c => {
          return data.columns.includes(c.name);
        });
        this.toggleOpen = false;
      }

    });
  }

  SaveSelection() {
    let result = this.columns.map(a => a.name);
    this.columnService.save(this.authService.currentUser.value.userId, "inspection_history", result).then((data) => {
    });
  }

  downloadReport(id: number) {
    this.reportableService.getLiftingExam(id).then((data) => {
      importedSaveAs(data, 'Inspection Report.pdf');
    });
  }

  downloadchecklistReport(id: number) {
    this.reportableService.getChecklist(id).then((data) => {
      importedSaveAs(data, 'Checklist Report.pdf');
    });
  }


  dateChange(ev) {
    if (ev == -1) {
      return;
    }

    let date = moment();
    if (ev == -2) {
      date.set('year', 2010);
      date.set('month', 0);
      date.set('date', 1);
    }
    else {
      if (ev == 0) {
        date = date.subtract(1, 'weeks');
      } else {
        date = date.subtract(ev, 'months');
      }
    }

    let jsDate = date.toDate();
    //var startDate: NgbDateStruct = { year: jsDate.getFullYear(), month: jsDate.getMonth() + 1, day: jsDate.getDate() };
    this.filter.start_Date = jsDate;
  }
  //Basket
  getImages(inspectionId) {
    this.inspectionService.getImages(inspectionId).then((data) => {
      importedSaveAs(data, 'Images.zip');
    });
  }

  addToBasket(item, type) {
    if (type == 1)
      this.basketService.addROTEtoBasket(item);
    else if (type == 2)
      this.basketService.addServicetoBasket(item);
    else if (type == 3)
      this.basketService.addRAMStoBasket(item);
    else if (type == 4)
      this.basketService.addESTRtoBasket(item);
    else if (type == 6)
      this.basketService.addChecklisttoBasket(item);

  }

  removeFromBasket(item, type) {
    if (type == 1)
      this.basketService.removeROTE(item);
    else if (type == 2)
      this.basketService.removeService(item);
    else if (type == 3)
      this.basketService.removeRAMS(item);
    else if (type == 4)
      this.basketService.removeESTR(item);
    else if (type == 6)
      this.basketService.removeChecklist(item);

  }

  resetItem(item) {
    item.addedrote = false;
    item.addedrams = false;
    item.addedestr = false;
    item.addedchecklist = false;
  }

  resetItems() {
    for (let item of this.rows) {
      this.resetItem(item);
    }
  }

  basketCompleted(data) {
    this.resetItems();
  }

  basketClearing(data) {
    this.resetItems();
  }

  

  refreshbasketrow() {
    console.log("REFRESHING");
    var items = this.basketService.getitems();
    for (let i = 0; i < (this.rows.length); i++) {
      let row = this.rows[i];
      for (let k = 0; k < (items.value.length); k++) {
        let item = items.value[k];
        if (item.report_Type == 1) { //ROTE
          if ((item.description == row.description) && (item.serial_Num == row.serial_Num)) {
            this.rows[i].addedrote = true;
          }
        } else if (item.report_Type == 2) { //SERVICE
          if ((item.description == row.description) && (item.serial_Num == row.serial_Num)) {
            this.rows[i].addedservice = true;
          }
        } else if (item.report_Type == 3) { //RAMS
          if ((item.guid == row.ramS_Id) || (item.guid == row.location_Inspection_Id)) {
            this.rows[i].addedrams = true;
          }
        } else if (item.report_Type == 4) { //ERSTR
          if ((item.description == row.engineer) && (item.serial_Num == row.job_No)) {
            this.rows[i].addedestr = true;
          }
        } else if (item.report_Type == 6) { //Checklist
          if ((item.description == row.description) && (item.serial_Num == row.serial_Num)) {
            this.rows[i].addedchecklist = true;
          }
        }
      }

    }
  }


  basketOnclickEvent(ev) {
    console.log(ev);
    console.log("CLICKED");
    //   this.refreshbasketrow();
    for (let i = 0; i < (this.rows.length); i++) {
      let row = this.rows[i];
      if (ev.report_Type == 1) { //ROTE
        if ((ev.description == row.description) && (ev.serial_Num == row.serial_Num)) {
          this.rows[i].addedrote = false;
        }
      } else if (ev.report_Type == 2) { //SERVICE
        if ((ev.description == row.description) && (ev.serial_Num == row.serial_Num)) {
          this.rows[i].addedservice = false;
        }
      } else if (ev.report_Type == 3) { //RAMS
        if ((ev.guid == row.ramS_Id) || (ev.guid == row.location_Inspection_Id)) {
          this.rows[i].addedrams = false;
        }
      } else if (ev.report_Type == 4) { //ERSTR
        if ((ev.description == row.engineer) && (ev.serial_Num == row.job_No)) {
          this.rows[i].addedestr = false;
        }
      } else if (ev.report_Type == 6) { //Checklist
        if ((ev.description == row.description) && (ev.serial_Num == row.serial_Num)) {
          this.rows[i].addedchecklist = false;
        }
      }
    }
  }
  gotoTop() {
    console.log("GOTO TOP");
    // window.scrollTo(-9999,-99999);
    let top = document.getElementById('top');
    console.log(top);
    if (top !== null) {
      top.scrollIntoView(true);
      //  document.getElementById("top").scrollTop -= 99999;
      top = null;
    }
  }

}

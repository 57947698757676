import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Basket_Item } from '../../models/Basket_Item';
import * as FileSaver from 'file-saver';
import { BasketService } from '../../services/basket.service';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.css']
})
export class BasketComponent implements OnInit {
  @Output() completed: EventEmitter<any> = new EventEmitter();
  @Output() clearing: EventEmitter<any> = new EventEmitter();
  @Output() removing: EventEmitter<any> = new EventEmitter();

  basketItems: Array<Basket_Item>;

  isOpen: boolean = false;

  typeId: number = 0;
  fileName: string = '';
  email: string = '';

  constructor(private basketService: BasketService, private confirmationService: ConfirmationService, private router: Router, private snotifyService: SnotifyService) { }

  ngOnInit() {
    this.basketService.basketItems.subscribe(data => {
      this.basketItems = data;
    });
  }

  downloadZip() {
    this.basketService.getZip().then((data => {
      FileSaver.saveAs(data, 'reports.zip');
      this.resetItems();
    }))
  }

  sendemail() {
    if (this.email == '') {
      this.snotifyService.error('Please enter Email address');
      return;
    }
    this.confirmationService.confirm({
      message: 'Are you sure that you want to send ' + this.basketItems.length + ' items to: ' + this.email ,
      accept: () => {
        this.basketService.emailZip(this.email).then((data => {
          //FileSaver.saveAs(data, 'reports.zip');
          
        }))
        this.resetItems();
        this.clear();
      }
    });
    
  }

  downloadBatch() {

    this.basketService.getBatchReport().then((data => {
      FileSaver.saveAs(data, 'BatchReport.pdf');
      this.resetItems();
    }))
  }

  resetItems() {
    this.email = '';
    this.completed.emit("Completed");
    
  }

  clear() {
    this.email = '';
    this.basketService.clear();
    this.clearing.emit("Clearing");
    
  }

  remove(item) {
    console.log(item);
    this.basketService.remove(item);
    this.removing.emit(item);
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }
}

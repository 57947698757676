import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from '../../../../services/company.service';
import { Company } from '../../../../models/company';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-company-manage',
  templateUrl: './company-manage.component.html',
  styleUrls: ['./company-manage.component.css']
})
export class CompanyManageComponent implements OnInit {
  company: Company;
  constructor(private companyService: CompanyService, public router: Router, public snotifyService: SnotifyService, public route: ActivatedRoute) { }

  ngOnInit() {
    this.company = new Company();
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id != undefined) {
        console.log(id);
        this.companyService.get(id).then((data) => {
          console.log(data);
          this.company = data;
          this.company.is_edit = true;
        });
      }
    });
  }

  submit() {
    if (!this.valid()) {
      return;
    }
    this.company.depot_ID = +this.company.depot_ID;
    this.companyService.save(this.company).then((data) => {

      if (data.success) {
        if (this.company.is_edit) {
          this.snotifyService.success('Successfully updated company');
        } else {
          this.snotifyService.success('Successfully added company');
        }
        this.router.navigate(['administration/companies']);
      } else {
        this.snotifyService.error(data.message);
      }
      
    });
  }
  valid() {
    var valid = true;
    if (this.company.companyName == undefined || this.company.companyName == "") {
      valid = false;
      this.snotifyService.error("Please enter company name.");
    }
    if (this.company.address1 == undefined || this.company.address1 == "") {
      valid = false;
      this.snotifyService.error("Please enter address 1.");
    }
    return valid;
  }

}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Category } from '../../../../models/category';
import { CategoryService } from '../../../../services/category.service';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-category-manage',
  templateUrl: './category-manage.component.html',
  styleUrls: ['./category-manage.component.css']
})
export class CategoryManageComponent implements OnInit {
  category: Category;
  parent_Categories;

  constructor(public categoryService: CategoryService, public router: Router, public snotifyService: SnotifyService, private route: ActivatedRoute) {
    this.category = new Category();
    //this.categoryService.getParentCategories().then((data) => {
    //  this.parent_Categories = data;
    //});

  }

  ngOnInit() {
    // Load our id if one exists
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id != undefined) {
        this.categoryService.get(id).then((data) => {
          this.category = data;
          this.category.is_edit = true;
        });
      }
    });
  }

  submit() {
    if (!this.valid()) {
      return;
    }
    this.category.insp_Time_Period = +this.category.insp_Time_Period;
    this.category.report_Type_ID = +this.category.report_Type_ID;
    this.categoryService.save(this.category).then((data) => {
      if (data.success) {
        if (!this.category.is_edit) {
          this.snotifyService.success("Successfully added category.");
        } else {
          this.snotifyService.success("Successfully updated category.");
        }
        this.router.navigate(['administration/categories']);
      } else {
        this.snotifyService.error(data.message);
      }
      
    });
  }
  valid() {
    var valid = true;
    if (this.category.category_Name == undefined || this.category.category_Name == "") {
      valid = false;
      this.snotifyService.error("Please enter category name.");
    }

    return valid;
  }

}

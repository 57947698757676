import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { CompanyService } from '../../../../services/company.service';
import { LocationService } from '../../../../services/location.service';
import { EquipmentService } from '../../../../services/equipment.service';
import { ManufacturerService } from '../../../../services/manufacturer.service';
import { CategoryService } from '../../../../services/category.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Equipment } from '../../../../models/equipment';
import { Equipment_Extra } from '../../../../models/equipment_Extra';
import { SnotifyService } from 'ng-snotify';
import * as moment from 'moment';
import { Manufacturer } from '../../../../models/manufacturer';
import { DescriptionsService } from '../../../../services/descriptions.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-equipment-manage',
  templateUrl: './equipment-manage.component.html',
  styleUrls: ['./equipment-manage.component.css']
})
export class EquipmentManageComponent implements OnInit {

  companies;
  locations;
  manufacturers: Manufacturer[] = [];
  categories;
  descriptions;

  equipment: Equipment;

  locationList;
  descriptionList;

  is_Crane;
  is_Jib;
  is_Edit = false;
  user;
  filter: any = {
    company_Id: 0,
    location_Id: undefined,
    category_Id: 0,
    serial_Num: '',
    asset_Num: ''
  };
  

  constructor(public companyService: CompanyService,public descriptionService: DescriptionsService, public locationService: LocationService, public equipmentService: EquipmentService, manufacturerService: ManufacturerService,
    categoryService: CategoryService, public router: Router, private route: ActivatedRoute, private snotifyService: SnotifyService,
    public confirmationService: ConfirmationService) {
    this.equipment = new Equipment();
    this.equipment.inspection_Status = -1;
    this.equipment.location_Id = -1;
    this.equipment.company_Id = -1;
    this.equipment.owner_ID = 1;
    this.equipment.cE_Num = '';
    this.equipment.category_ID = -1;
    this.is_Edit = false;
    this.user = localStorage.getItem("currentUser");
    this.user = JSON.parse(this.user);
    this.equipment.userId = this.user.userId

    
    companyService.getAll().then((data) => {
      this.companies = data;
    });
    manufacturerService.getAll().then((data) => {
      this.manufacturers = data;
      console.log(this.manufacturers);
    });

    locationService.getAll().then((data) => {
      this.locationList = data;
      this.locations = this.locationList;
      if (this.equipment.company_Id != 0 && this.equipment.company_Id != -1) {
        this.locations = this.locationList.filter(c => {
          return c.companyId == this.equipment.company_Id;
        });
      }
    });

    categoryService.getAll().then((data) => {
      this.categories = data;
      console.log(this.categories);
    });

    descriptionService.getAll().then((data) => {
      this.descriptionList = data;
      this.descriptions = this.descriptionList;
      console.log(this.equipment.category_ID);
      if (this.equipment.category_ID != 0 && this.equipment.category_ID != -1) {
        this.descriptions = this.descriptionList.filter(d => {
          return d.category_ID == this.equipment.category_ID;
        });
      }
    });

    
  }

  dropdownWidth: string = '100%'; 
  @ViewChild('dropdownContainer') dropdownContainer!: ElementRef;

  ngAfterViewInit() {
    this.resetDropdownWidth();
  }

  expandDropdownWidth() {
    const textWidths = this.descriptions.map(desc => this.getTextWidth(desc.name));
    const maxWidth = Math.max(...textWidths, this.dropdownContainer.nativeElement.offsetWidth);
    this.dropdownWidth = maxWidth  + 'px'; 
  }

  resetDropdownWidth() {
    this.dropdownWidth = '100%';
  }

  getTextWidth(text: string) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = '16px Arial';
    return context.measureText(text).width;
  }

  onCompanyChange(value) {
    console.log("hit on company change");
    this.filter.location_Id = -1;
    if (value == 0) { value = null; }

    this.locations = this.locationList.filter(c => {
      return value == null || c.companyId == value;
    });
    this.equipment.location_Id = null;
  }



  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      if (id != undefined) {
        this.equipmentService.get(id).then((data) => {
          this.equipment = data;
          console.log(this.equipment);
          this.equipment.is_edit = true;
          this.is_Edit = true;
          if (this.equipment.date_Of_Manuf != null && this.equipment.date_Of_Manuf != undefined && moment(this.equipment.date_Of_Manuf).format("YYYY-MM-DD") != moment("0001-01-01").format("YYYY-MM-DD")) {
            var str = this.equipment.date_Of_Manuf.split("T", 2);
            this.equipment.date_Of_Manuf = moment(str[0]).format("YYYY-MM-DD");
          } else {
            this.equipment.date_Of_Manuf = undefined;
          }

          if (this.equipment.date_Of_First_Use != null && this.equipment.date_Of_First_Use != undefined && moment(this.equipment.date_Of_First_Use).format("YYYY-MM-DD") != moment("0001-01-01").format("YYYY-MM-DD") ) {
            var str2 = this.equipment.date_Of_First_Use.split("T", 2);
            this.equipment.date_Of_First_Use = moment(str2[0]).format("YYYY-MM-DD");
          } else {
            this.equipment.date_Of_First_Use = undefined;
          }

          if (this.equipment.date_Of_Last_Inspection != null && this.equipment.date_Of_Last_Inspection != undefined && moment(this.equipment.date_Of_Last_Inspection).format("YYYY-MM-DD") != moment("0001-01-01").format("YYYY-MM-DD")) {
            var str3 = this.equipment.date_Of_Last_Inspection.split("T", 2);
            this.equipment.date_Of_Last_Inspection = moment(str3[0]).format("YYYY-MM-DD");
          } else {
            this.equipment.date_Of_Last_Inspection = undefined;
          }

          if (this.equipment.last_Inspection_ID == 0) {
            this.equipment.inspection_Status = -1;
          }

          if (this.equipment.company_Id != 0 && this.equipment.company_Id != -1) {
            this.locations = this.locationList.filter(c => {
              return c.companyId == this.equipment.company_Id;
            });
          }
          console.log(this.equipment.category_ID);
          if (this.equipment.category_ID != 0 && this.equipment.category_ID != -1) {
            this.descriptions = this.descriptionList.filter(d => {
              return d.category_ID == this.equipment.category_ID;
            });
          }

          
          
          //this.locations = this.locationList.filter(c => {
          //  return c.company_Id == this.equipment.company_Id;
          //});
          //this.descriptions = this.descriptionList.filter(c => {
          //  return c.category_ID == this.equipment.category_ID;
          //});
          //this.equipment.location_Id == this.equipment.location_Id;
          //this.onCategoryChange(this.equipment.category_ID);
        });
      }
    });
  }

  onCategoryChange(value) {

    this.filter.category_Id = -1;
    if (value == 0) { value = null; }

    var cat = this.categories.filter(c => {
      return c.category_ID == value;
    });

    if (cat.length > 0) { this.equipment.inspection_Interval = cat[0].insp_Time_Period; }
    

    this.descriptions = this.descriptionList.filter(c => {
      return value == null || c.category_ID == value;
    });
    this.equipment.description_ID = null;

    
  }

  onDescriptionChange(value) {
    console.log(value);
    this.equipment.description = value;

    
  }

  addExtra() {
    if (this.equipment.extras.length >= 3) {
      this.snotifyService.warning('You cannot have more than 3 additional Details');
      return;
    }
    const extra = new Equipment_Extra();
    extra.manuf_ID = +extra.manuf_ID;

    this.equipment.extras.push(extra);
    console.log(this.equipment.extras);
  }

  removeExtra(extra) {
    this.equipment.extras.splice(this.equipment.extras.indexOf(extra), 1);
  }

  navigateback() {
    this.router.navigate(['administration/equipment']);
  }
  valid() {
    var valid = true;
    for (const extra of this.equipment.extras) {

      if (extra.serial_Num == '' || extra.model == '' || extra.hoist_Bs_Class == ''
        || extra.ct_Bs_Class == '' || extra.manuf_ID == undefined) {
        this.snotifyService.error('Please enter all fields for Additional Details');
        valid = false;
        break;
      } else {
        extra.manuf_ID = + extra.manuf_ID;
      }
    }

    if (this.equipment.company_Id == undefined || this.equipment.company_Id < 1) {
      this.snotifyService.error('Please enter company');
      valid = false;
    }
    if (this.equipment.location_Id == undefined || this.equipment.location_Id < 1) {
      this.snotifyService.error('Please enter location');
      valid = false;
    }
    if (this.equipment.category_ID == undefined || this.equipment.category_ID < 1) {
      this.snotifyService.error('Please enter category');
      valid = false;
    }
    if (this.equipment.description_ID == undefined || this.equipment.description_ID < 1) {
      this.snotifyService.error('Please select a description');
      valid = false;
    }
    if (this.equipment.manuf_ID == undefined || this.equipment.manuf_ID < 1 ) {
      this.snotifyService.error('Please select a manufacturer');
      valid = false;
    }
    if ((this.equipment.serial_Num == null || this.equipment.serial_Num == undefined || this.equipment.serial_Num == '') &&
      (this.equipment.asset_Num == null || this.equipment.asset_Num == undefined || this.equipment.asset_Num == '')) {
      this.snotifyService.error('Please enter a serial or asset number');
      valid = false;
    }
    if (this.equipment.date_Of_Manuf == undefined ||this.equipment.date_Of_Manuf == "") {
      this.equipment.date_Of_Manuf = null;
    } else {
      if (moment(this.equipment.date_Of_Manuf).isValid()) {
        if (moment(this.equipment.date_Of_Manuf) >= moment().add(1, 'days').startOf('day')) {
          this.snotifyService.error('Date of Manufacture cannot be a date in the future.');
          valid = false;
        }
      }
      else {
        this.snotifyService.error('Invalid date of manufacturer');
        valid = false;
      }
    }
    if (this.equipment.swl == undefined || this.equipment.swl == "") {
      this.snotifyService.error('Please enter SWL');
      valid = false;
    }
    if ((this.equipment.inspection_Interval == undefined || this.equipment.inspection_Interval == 0) &&
      (this.equipment.inspection_Interval_Week == undefined || this.equipment.inspection_Interval_Week == 0) ) {
      this.snotifyService.error('Please enter a inspection interval week or month');
      valid = false;
    }

    if (this.equipment.date_Of_First_Use == undefined || this.equipment.date_Of_First_Use == "") {
      this.equipment.date_Of_First_Use = null;
    } else {
      if (moment(this.equipment.date_Of_First_Use).isValid()) {
        if (moment(this.equipment.date_Of_First_Use) >= moment().add(1, 'days').startOf('day')) {
          this.snotifyService.error('Date of first use cannot be a date in the future.');
          valid = false;
        }
      }
      else {
        this.snotifyService.error('Invalid date of first use');
        valid = false;
      }
    }




    if (this.equipment.inspection_Status != -1) {
      if (this.equipment.date_Of_Last_Inspection == undefined || this.equipment.date_Of_Last_Inspection == null) {
        this.snotifyService.error('Please enter inspection date');
        valid = false;
      }

      if (moment(this.equipment.date_Of_Last_Inspection).isValid()) {
        if (moment(this.equipment.date_Of_Last_Inspection) >= moment().add(1, 'days').startOf('day')) {
          this.snotifyService.error('Date of last inspection cannot be a date in the future.');
          valid = false;
        }
      }
      else {
        this.snotifyService.error('Invalid date of last inspection');
        valid = false;
      }

    }
    
    
    if (this.equipment.date_Of_Last_Inspection == undefined || this.equipment.date_Of_Last_Inspection == "") {
      this.equipment.date_Of_Last_Inspection = null;
    }
    return valid;
  }

  submit() {
    if (!this.valid()) {
      return;
    }
    console.log("submitting");
    this.equipment.category_ID = +this.equipment.category_ID;
    this.equipment.company_Id = +this.equipment.company_Id;
    this.equipment.inspection_Status = +this.equipment.inspection_Status;
    this.equipment.location_Id = +this.equipment.location_Id;
    this.equipment.manuf_ID = +this.equipment.manuf_ID;
    this.equipment.owner_ID = +this.equipment.owner_ID;
    console.log(this.equipment)
    this.equipmentService.save(this.equipment).then((data) => {
      if (data.success) {
        if (!this.equipment.is_edit) {
          this.snotifyService.success('Successfully added asset');
          this.addDuplicate();
        } else {
          this.snotifyService.success('Successfully updated asset');
          this.navigateback();
        }

      } else {
        this.snotifyService.warning(data.message);
      }


    });


  }

  addDuplicate() {
    this.confirmationService.confirm({
      message: 'Would you like to add anouther Asset with these details?</br><br/>',
      accept: () => {
        this.equipment.serial_Num = '';
      },
      reject: () => {
        this.navigateback();
      }

    });
  }

}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { EquipmentService } from '../../../../services/equipment.service';
import { InspectionService } from '../../../../services/inspection.service';
import { TabletService } from '../../../../services/tablet.service';
import * as moment from 'moment';
import { Inspection } from '../../../../models/inspection';
import { ColourService } from '../../../../services/colour.service';
import { DescriptionsService } from '../../../../services/descriptions.service';
import { CategoryService } from '../../../../services/category.service';
import { ManufacturerService } from '../../../../services/manufacturer.service';
import { Manufacturer } from '../../../../models/manufacturer';
import { Page } from '../../../../models/paging/page';
import { toDate } from '@angular/common/src/i18n/format_date';
import { Equipment } from '../../../../models/equipment';
import { Tablet } from '../../../../models/tablet';
import { StorageService } from '../../../../services/storage.service';
import { ModalService } from '../../../../services/modal.service';
import { SnotifyService } from 'ng-snotify';


@Component({
  selector: 'app-inspection-edit',
  templateUrl: './inspection-edit.component.html',
  styleUrls: ['./inspection-edit.component.css']
})
export class InspectionEditComponent implements OnInit {
  colours;
  jobs;
  
  purposes;
  equipment;
  manufacturers: Manufacturer[] = [];
  categories;
  descriptionList;
  descriptions;
  user;
  originaljob;
  page = new Page();
  rows = Array<any>();

  inspection;

  interval: number = 0;

  //users: Tablet[];
  //pdA_User_ID: number = -1;
  //pin;
  //PDA_User: Tablet;


  isConfirmDialogVisible: boolean = false;
  confirmationMessage: string = '';

  constructor(private inspectionService: InspectionService, public descriptionService: DescriptionsService,
    private colourService: ColourService, private tabletService: TabletService, public categoryService: CategoryService,
    private equipmentService: EquipmentService, public manufacturerService: ManufacturerService, private confirmationService: ConfirmationService,
    private router: Router, private route: ActivatedRoute, public modalService: ModalService, public snotifyService: SnotifyService, public storageService: StorageService) {
    this.page.pageNumber = 0;
    this.page.size = 10;
    this.equipment = new Equipment();
    this.equipment.inspection_Status = -1;
    this.equipment.location_Id = -1;
    this.equipment.company_Id = -1;
    this.equipment.owner_ID = 1;
    this.equipment.cE_Num = '';
    this.equipment.category_ID = -1;
    this.user = localStorage.getItem("currentUser");
    this.user = JSON.parse(this.user);


    this.purposes = [
      { id: 1, purpose: "Within an interval of 6 months" }
      , { id: 2, purpose: "Within an interval of 12 months" }
      , { id: 3, purpose: "In accordance with an examination scheme" }
      , { id: 4, purpose: "After an occurence of exceptional circumstance" }
    ];
    

    this.inspection = new Inspection();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.colourService.getAll().then((data) => {
        this.colours = data;
      });
      

      //this.tabletService.getAll().then((data) => {
      //  this.users = data;
      //});

      this.manufacturerService.getAll().then((data) => {
        this.manufacturers = data;
      });
      this.categoryService.getAll().then((data) => {
        this.categories = data;
        console.log(this.categories);
      });

      this.descriptionService.getAll().then((data) => {
        this.descriptionList = data;
        this.descriptions = this.descriptionList;
        console.log(this.equipment.category_ID);
        if (this.equipment.category_ID != 0 && this.equipment.category_ID != -1) {
          this.descriptions = this.descriptionList.filter(d => {
            return d.category_ID == this.equipment.category_ID;
          });
        }
      });

      const id = params['id'];
      this.inspectionService.getJobs(id).then((data) => {
        this.jobs = data;
        console.log(this.jobs);
      });

      if (id != undefined) {
        this.inspectionService.get(id).then((data) => {
          this.inspection = data;
          this.originaljob = this.inspection.location_Inspection_ID;
          this.inspection.location_Inspection_ID = undefined;
          this.rows = data.answered_Questions;

          this.equipment = data.equipment;

          console.log(this.inspection);
          if (this.equipment.date_Of_Manuf != null && this.equipment.date_Of_Manuf != undefined && moment(this.equipment.date_Of_Manuf).format("YYYY-MM-DD") != moment("0001-01-01").format("YYYY-MM-DD")) {
            var str = this.equipment.date_Of_Manuf.split("T", 2);
            this.equipment.date_Of_Manuf = moment(str[0]).format("YYYY-MM-DD");
          } else {
            this.equipment.date_Of_Manuf = undefined;
          }
          if (this.inspection.inspected_At != null && this.inspection.inspected_At != undefined && moment(this.inspection.inspected_At).format("YYYY-MM-DD") != moment("0001-01-01").format("YYYY-MM-DD")) {
            var str = this.inspection.inspected_At.split("T", 2);
            this.inspection.inspected_At = moment(str[0]).format("YYYY-MM-DD");
          } else {
            this.inspection.inspected_At = undefined;
          }
          if (this.inspection.next_Inspection_Date != null && this.inspection.next_Inspection_Date != undefined && moment(this.inspection.next_Inspection_Date).format("YYYY-MM-DD") != moment("0001-01-01").format("YYYY-MM-DD")) {
            var str = this.inspection.next_Inspection_Date.split("T", 2);
            this.inspection.next_Inspection_Date = moment(str[0]).format("YYYY-MM-DD");
          } else {
            this.inspection.next_Inspection_Date = undefined;
          }

          if (this.inspection.danger_Date != null && this.inspection.danger_Date != undefined && this.inspection.danger_Date != '') {
            var str = this.inspection.danger_Date_Formatted.split("T", 2);
            console.log(str[0]);
            this.inspection.danger_Date = moment(str[0]).format("YYYY-MM-DD");
          }

          if (this.equipment.category_ID != 0 && this.equipment.category_ID != -1) {
            this.descriptions = this.descriptionList.filter(d => {
              return d.category_ID == this.equipment.category_ID;
            });
          }

        });
      }
    });
  }

  dropdownWidth: string = '100%';
  @ViewChild('dropdownContainer') dropdownContainer!: ElementRef;

  ngAfterViewInit() {
    this.resetDropdownWidth();
  }

  expandDropdownWidth() {
    const textWidths = this.descriptions.map(desc => this.getTextWidth(desc.name));
    const maxWidth = Math.max(...textWidths, this.dropdownContainer.nativeElement.offsetWidth);
    this.dropdownWidth = maxWidth + 'px';
  }

  resetDropdownWidth() {
    this.dropdownWidth = '100%';
  }

  getTextWidth(text: string) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = '16px Arial';
    return context.measureText(text).width;
  }

  statusChanged() {
    if (this.inspection.safe_For_Use) {
      let next_Inspection_Date = moment(this.inspection.inspected_At).add(this.equipment.inspection_Interval, 'M').format("YYYY-MM-DD");
      this.inspection.next_Inspection_Date = moment(next_Inspection_Date).format("YYYY-MM-DD");
    } else {
      this.inspection.next_Inspection_Date = moment(this.inspection.inspected_At).format("YYYY-MM-DD");
    }
  }

  dateChanged() {
    if (!this.inspection.safe_For_Use) {
      this.inspection.next_Inspection_Date = this.inspection.inspected_At;
    } else {
      let next_Inspection_Date = moment(this.inspection.inspected_At).add(this.equipment.inspection_Interval, 'M').format("YYYY-MM-DD");
      this.inspection.next_Inspection_Date = moment(next_Inspection_Date).format("YYYY-MM-DD");
    }
  }
  missingChanged() {
    if (this.inspection.missing || !this.inspection.safe_For_Use) {
      this.inspection.next_Inspection_Date = this.inspection.inspected_At;
    } else {
      let next_Inspection_Date = moment(this.inspection.inspected_At).add(this.equipment.inspection_Interval, 'M').format("YYYY-MM-DD");
      this.inspection.next_Inspection_Date = moment(next_Inspection_Date).format("YYYY-MM-DD");
    }
  }

  examChanged() {
    if (!this.inspection.first_Examination) {
      this.inspection.installed_Correctly = false;
    } 
  }

  onIntervalChange() {
    if (this.equipment.inspection_Interval == 6) {
      this.inspection.purpose_ID = 1
    }
    else if (this.equipment.inspection_Interval == 12) {
      this.inspection.purpose_ID = 2
    } else {
      this.inspection.purpose_ID = 3
    }
    if (this.inspection.safe_For_Use) {
      let next_Inspection_Date = moment(this.inspection.inspected_At).add(this.equipment.inspection_Interval, 'M').format("YYYY-MM-DD");
      this.inspection.next_Inspection_Date = moment(next_Inspection_Date).format("YYYY-MM-DD");
    } 
  }

  immediateChanged() {
    if (this.inspection.immediate_To_Persons) {
      this.inspection.become_Danger_To_Persons = false;
      this.inspection.danger_Date = '';
      this.inspection.repair = '';
    } 
  }
  becomeChanged() {
    if (this.inspection.become_Danger_To_Persons) {
      this.inspection.immediate_To_Persons = false;

    } 
  }
  onPurposeChange(value) {
    console.log(value)
    if (value == 1) {
      if (this.inspection.safe_For_Use) {
        let next_Inspection_Date = moment(this.inspection.inspected_At).add(6, 'M').format("YYYY-MM-DD");
        this.inspection.next_Inspection_Date = moment(next_Inspection_Date).format("YYYY-MM-DD");
      }
      this.equipment.inspection_Interval = 6
    }
    if (value == 2) {
      if (this.inspection.safe_For_Use) {
        let next_Inspection_Date = moment(this.inspection.inspected_At).add(12, 'M').format("YYYY-MM-DD");
        this.inspection.next_Inspection_Date = moment(next_Inspection_Date).format("YYYY-MM-DD");
      }
      this.equipment.inspection_Interval = 12
    }
    if (value == 3) {
      this.equipment.inspection_Interval = null;
      this.onIntervalChange();
    }

  }

  onDescriptionChange(value) {
    console.log(value);
    this.equipment.description = value;


  }
  onCategoryChange(value) {
    console.log(value);
    if (value == 0) { value = null; }

    var inspTimePeriod = this.categories
      .filter(c => value == null || c.category_ID == value) 
      .map(c => c.insp_Time_Period); 
    var TimePeriod = inspTimePeriod.length > 0 ? inspTimePeriod[0] : undefined;
    this.equipment.inspection_Interval = TimePeriod;
    console.log(this.equipment.inspection_Interval);
    this.onIntervalChange();

    this.descriptions = this.descriptionList.filter(c => {
      return value == null || c.category_ID == value;
    });

    this.equipment.description_ID = null;
  }
  passFail(row) {
    console.log(row);
    row.response = !row.response;
  }
  

  OnSubmit() {
    if (!this.valid()) {
      return;
    }
    if (this.inspection.location_Inspection_ID == undefined) {
      this.inspection.location_Inspection_ID = this.originaljob;
    }
    this.inspection.location_Inspection_ID = +this.inspection.location_Inspection_ID;

    let status = "";

    if (this.inspection.missing) {
      status = "MISSING asset";
    }
    else {
      if (this.inspection.safe_For_Use) {
        status = "PASS";
      } else {
        status = "FAIL";
      }
    }
    this.firstConfirm(status)
  }

  firstConfirm(status) {
    this.confirmationService.confirm({
      message: 'Are you sure this item is a ' + status + '?',
      key: 'confirmStatus',
      accept: () => {

        if (!this.inspection.safe_For_Use && !this.inspection.missing) {
          this.secondConfirm();
          return;
        } else {
          this.inspection.scrapped = false;
          this.inspection.decommisioned = false;
          return this.updateInspection();
        }
      },
      reject: () => {

      }
    });
  }
  secondConfirm() {
    console.log("second confirmation");
    this.confirmationService.confirm({
      message: 'This item is not safe for use. Would you like to scrap it?',
      key: 'confirmScrap',
      accept: () => {
        this.inspection.scrapped = true;
        this.inspection.decommisioned = true;

        this.updateInspection();
      },
      reject: () => {
        this.inspection.scrapped = false;
        this.inspection.decommisioned = false;

        this.updateInspection();
      }
    });
  }

  updateInspection() {
    console.log(this.inspection);
    this.inspectionService.update(this.inspection).then((data) => {
      this.router.navigate(['reports/inspections']);
    });
  }

  valid() {
    this.inspection.answered_Questions = this.rows;
    this.inspection.inspected_At = moment(this.inspection.inspected_At).format("YYYY-MM-DD");

    if (this.inspection.missing) {
      this.inspection.next_Inspection_Date = moment(this.inspection.inspected_At).format("YYYY-MM-DD");
    } else {
      this.inspection.next_Inspection_Date = moment(this.inspection.next_Inspection_Date).format("YYYY-MM-DD");
    }


    this.inspection.equipment.owner_ID = +this.inspection.equipment.owner_ID;
    this.inspection.colour_ID = +this.inspection.colour_ID;

    if (this.inspection.equipment.date_Of_Manuf != undefined) {
      this.inspection.date_Of_Manuf = moment(this.inspection.equipment.date_Of_Manuf).toDate();
    }

    

    this.inspection.web_User_ID = this.user.userId;
    //this.inspection.pda_User_ID = +this.PDA_User.pdA_User_ID;

    this.inspection.equipment = this.equipment;
    this.inspection.equipment.owner_ID = +this.inspection.equipment.owner_ID;
    this.inspection.colour_Id = +this.inspection.colour_ID;
    this.inspection.purpose_ID = +this.inspection.purpose_ID;
    


    var valid = true;
    if (this.equipment.category_ID == undefined ||this.equipment.category_ID == 0) {
      valid = false;
      this.snotifyService.error("Please select category.");
    }
    if (this.equipment.description_ID == undefined || this.equipment.description_ID == 0) {
      valid = false;
      this.snotifyService.error("Please select description.");
    }
    if (this.equipment.swl == undefined || this.equipment.swl == "") {
      valid = false;
      this.snotifyService.error("Please enter SWL.");
    }
    if ((this.equipment.serial_Num == undefined ||this.equipment.serial_Num == "" ) &&
      (this.equipment.asset_Num == undefined || this.equipment.asset_Num == "")) {
      valid = false;
      this.snotifyService.error("Please enter a serial number or asset number.");
    }
    if (this.equipment.manuf_ID == undefined || this.equipment.manuf_ID == 0) {
      valid = false;
      this.snotifyService.error("Please select manufacturer.");
    }
    if (this.equipment.inspection_Interval == undefined || this.equipment.inspection_Interval == 0 ) {
      valid = false;
      this.snotifyService.error("Please enter asset inspection interval.");
    }
    if (this.equipment.date_Of_Manuf == undefined || this.equipment.date_Of_Manuf == "") {
      this.equipment.date_Of_Manuf = null;
    } else {
      if (moment(this.equipment.date_Of_Manuf).isValid()) {
        if (moment(this.equipment.date_Of_Manuf) >= moment().add(1, 'days').startOf('day')) {
          this.snotifyService.error('Date of Manufacture cannot be a date in the future.');
          valid = false;
        }
      }
      else {
        this.snotifyService.error('Invalid date of manufacturer');
        valid = false;
      }
    }


    if (this.inspection.become_Danger_To_Persons == true && (this.inspection.danger_Date == null || this.inspection.danger_Date == undefined || !moment(this.inspection.danger_Date).isValid())) {
      valid = false;
      this.snotifyService.error("Please select when this defect could become a danger by.");
    }

    if (this.inspection.become_Danger_To_Persons === true) {
      this.inspection.danger_Date = moment(this.inspection.danger_Date).format("DD/MM/YYYY 00:00:00");
    } else {
      this.inspection.danger_Date = null;
    }
    return valid;
  }

}

export class Tablet {
  pdA_User_ID ;
  firstname = '';
  lastname = '';
  pin = '';
  pin2 = '';
  android_PIN = '';
  expired = false;
  competent = false;
  expired_Formatted: string;
  created_Date: string;
  isLead = false
  requiresSTR = false
  admin_User = false


  is_edit = false;

  
}

import { Component, OnInit } from '@angular/core';
import { WebUser } from '../../../../models/webUser';
import { WebUserService } from '../../../../services/webUser.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../../services/company.service';
import { Company } from '../../../../models/company';
import { Location } from '../../../../models/location';
import { LocationService } from '../../../../services/location.service';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-web-manage',
  templateUrl: './web-manage.component.html',
  styleUrls: ['./web-manage.component.css']
})
export class WebManageComponent implements OnInit {
  user: WebUser;
  companies: Company[];
  error: "";
  isEdit: boolean;

  locationsList: Location[];
  locations: Location[];

  constructor(private companyService: CompanyService, public locationService: LocationService, public snotifyService: SnotifyService, public webUserService: WebUserService, public router: Router, public route: ActivatedRoute) { }

  ngOnInit() {
    this.isEdit = false;
    this.user = new WebUser();
    this.companyService.getAll().then((data) => {
      this.companies = data;
      this.locationService.getAll().then((data) => {
        this.locationsList = data;

        this.route.queryParams.subscribe(params => {
          const id = params['id'];
          if (id != undefined) {
            this.isEdit = true;
            this.webUserService.get(id).then((data) => {
              console.log(data);
              this.locations = this.locationsList.filter(c => {
                return c.companyId == data.company_Id
              });

              this.user = data;
              this.user.email2 = data.email;
              this.user.is_edit = true;
            });
          }
        });
      });

      
    });
  }

  onCompanyChange(value) {
    if (value == 0) { value = null; }

    this.locations = this.locationsList.filter(c => {
      return value == null || c.companyId == value;
    });
  }

  valid() {
    var valid = true;
    if (this.user.firstname == undefined || this.user.firstname == "") {
      valid = false;
      this.snotifyService.error("Please enter firstname");
    }
    if (this.user.lastname == undefined || this.user.lastname == "") {
      valid = false;
      this.snotifyService.error("Please enter lastname");
    }
    if (this.user.company_Id == undefined || this.user.company_Id < 1) {
      valid = false;
      this.snotifyService.error("Please select a company");
    }
    if (this.user.password != this.user.password2) {
      valid = false;
      this.snotifyService.error("Passwords do not match");
    }


    if (this.user.email != this.user.email2) {
      valid = false;
      this.snotifyService.error("emails do not match");
    }

    if (!this.isEdit) {
      if (this.user.password.length < 1) {
        valid = false;
        this.snotifyService.error("Need a longer password");
      }
    }
    if (this.user.email.length < 1) {
      valid = false;
      this.snotifyService.error("please enter a email");
    }
    return valid;
  }

  submit() {
    if (!this.valid()) {
      return;
    }
    this.user.company_Id = +this.user.company_Id;
    this.user.userType = +this.user.userType;
    this.user.depot_ID = +this.user.depot_ID;


    this.webUserService.save(this.user).then((data) => {
      if (data.success) {
        if (!this.isEdit) {
          this.snotifyService.success('Successfully added user');
        } else {
          this.snotifyService.success('Successfully updated user');
        }
        this.router.navigate(['users/web']);
      } else {
        this.snotifyService.error(data.message);
      }
    }).catch((err) => {
      this.error = err.error;
    });
  }

}

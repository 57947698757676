import { Answered_Questions } from "./answered_Questions";
import { Equipment } from "./equipment";
import { Location_Inspection } from "./location_inspection";
import { Tablet } from "./tablet";

export class Inspection {
  id = 0;
  equipment_Id = '';
  inspected_At;
  submittedAt;
  tabletUser_Id = '';
  tabletUser: Tablet;
  locationInspectionId: string | null = null;
  locationInspection: Location_Inspection | null = null;
  missing = false;
  safe_For_Use = false;
  next_Inspection_Date;
  previous_InspectionDate: Date | null = null;
  purpose_Of_Examination_Id = 0;
  purpose_ID = 0;
  first_Examination = false;
  installed_Correctly = false;
  immediate_To_Persons = false;
  scrapped = false;
  decommisioned = false;
  danger_To_Persons = '';
  become_Danger_To_Persons;
  repair = '';
  repair_Date;
  particulars = '';
  danger_Date = '';
  
  colour_Id = 0;
  PDA_User_ID = 0;
  location_Inspection_ID = 0;

  archived = false;
  answered_Questions: Answered_Questions[] = [];
  equipment: Equipment;
}
